<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-checkbox form-element {{ config.inlineLabel ? 'inline-label' : 'not' }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [class.expanded-height]="config.expandedHeight"
  [attr.id]="config.name"
>
  <ng-container *ngIf="config.inlineLabel">
    <div class="label half-width"></div>
  </ng-container>

  <mat-checkbox
    *ngIf="!config.updater && !config.customEventMethod"
    color="primary"
    ix-auto
    ix-auto-type="checkbox"
    class="{ 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    ix-auto-identifier="{{ config.placeholder }}"
    [formControlName]="config.name"
    [required]="config.required"
    (click)="config.readonly && preventClick($event)"
    (change)="onChangeCheckbox($event)"
  >
    {{ config.placeholder | translate }}
  </mat-checkbox>

  <mat-checkbox
    *ngIf="config.updater"
    color="primary"
    class="updater { 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    [formControlName]="config.name"
    [required]="config.required"
    (change)="onChangeCheckbox($event)"
    (click)="(config.readonly && preventClick($event)) || checkboxUpdate()"
  >
    {{ config.placeholder | translate }}
  </mat-checkbox>

  <mat-checkbox
    *ngIf="config.customEventMethod"
    color="primary"
    class="custom-handler { 'full-width': !config.inlineLabel, 'half-width': config.inlineLabel }"
    [formControlName]="config.name"
    [required]="config.required"
    (change)="onChangeCheckbox($event)"
    (click)="preventClick($event) && config.customEventMethod()"
  >
    {{ config.placeholder | translate }}
  </mat-checkbox>

  <span *ngIf="config.required">*</span>
  <ix-tooltip
    *ngIf="config.tooltip"
    [header]="config.placeholder"
    [message]="config.tooltip"
  ></ix-tooltip>

  <mat-error *ngIf="config['hasErrors']">
    <div [innerHTML]="config['errors']"></div>
  </mat-error>

  <mat-error *ngIf="config.warnings">
    <div [innerHTML]="config.warnings | translate"></div>
  </mat-error>
</div>
