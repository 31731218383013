<div #tooltip class="tooltip">
  <popper-content #popperTooltip class="fn-theme-tooltip">
    <div class="tooltip-header">
      <div class="title-wrapper">
        <div>
          <h5 class="tooltip-title">
            {{ 'Help' | translate }}{{ header ? ': ' : '' }}
            <i>{{ header ? (header | translate) : '' }}</i>
          </h5>
        </div>
      </div>
      <div>
        <mat-icon
          class="close-icon"
          fontSet="mdi-set"
          fontIcon="mdi-close"
          ix-auto
          ix-auto-type="tooltip-close"
          ix-auto-identifier="{{ header }}"
          (click)="popperTooltip.hide()"
        ></mat-icon>
      </div>
    </div>
    <div
      class="tooltip-message"
      ix-auto
      ix-auto-type="tooltip-message"
      ix-auto-identifier="{{ header }}"
      [innerHTML]="message | translate"
    ></div>
  </popper-content>

  <img />

  <i
    class="material-icons tooltip-icon"
    popperAppendTo="body"
    ix-auto
    ix-auto-type="tooltip-icon"
    ix-auto-identifier="{{ header }}"
    [popperTrigger]="'click' | cast"
    [popper]="popperTooltip"
    [popperShowOnStart]="false"
  >help_outline</i>
</div>
