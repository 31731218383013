<div
  *ngIf="!config['isHidden']"
  class="dynamic-field mat-radio"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <div class="top">
    <label>{{ config.placeholder | translate }}</label>
    <ix-tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
      [style.margin-left.px]="5"
    ></ix-tooltip>
  </div>

  <mat-radio-group
    ix-auto
    ix-auto-type="radio-group"
    id="{{ config.name }}_radiogroup"
    ix-auto-identifier="{{ config.name }}"
    [formControlName]="config.name"
    [fxLayout]="radioLayout"
    (change)="onChangeRadio($event)"
  >
    <div
      *ngFor="let option of config.options"
      fxFlex="100%"
      fxLayout="row"
      [fxFlex.gt-md]="radioFlex"
    >
      <mat-radio-button
        *ngIf="!option.hiddenFromDisplay"
        ix-auto
        ix-auto-type="radio"
        id="{{ config.name }}_{{ option.value }}_radiobutton"
        ix-auto-identifier="{{ config.name }}_{{ option.label }}"
        [checked]="option.value === radioValue"
        [value]="option.value"
      >
        {{ option.label | translate }}
      </mat-radio-button>

      <ix-tooltip
        *ngIf="option.tooltip"
        class="radio-option-tooltip"
        [header]="config.placeholder"
        [message]="option.tooltip"
      ></ix-tooltip>
    </div>
  </mat-radio-group>

  <mat-error *ngIf="config['hasErrors']">
    <div [innerHTML]="config['errors']"></div>
  </mat-error>

  <mat-error *ngIf="config.warnings">
    <div [innerHTML]="config.warnings | translate"></div>
  </mat-error>
</div>
