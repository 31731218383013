<div
  *ngIf="!config['isHidden']"
  id="config.name"
  fxLayout="column"
  class="dynamic-field mat-dict"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
>
  <div *ngIf="config.placeholder" class="label-container">
    <label class="label input-text">{{ config.label | translate }}</label>
    <ix-tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
    ></ix-tooltip>
  </div>

  <ng-container *ngFor="let field of config.subFields">
    <div
      *ngIf="!field.isHidden"
      fxFlex="calc(100% - 100px)"
      fxFlex.gt-xs="calc({{ field.width }} - 100px - 32px)"
      [class.form-inline]="field.class === 'inline'"
      [class.form-line]="field.class !== 'inline'"
    >
      <div dynamicField [config]="field" [group]="dictFormGroup"></div>
    </div>
  </ng-container>
</div>
