<h1 mat-dialog-title>{{ title | translate }}</h1>
<mat-dialog-content
  class="entity-job-dialog"
  ix-auto
  ix-auto-type="dialog"
  ix-auto-identifier="title"
>
  <div *ngIf="showRealtimeLogs && realtimeLogs" class="logs-container">
    <h5>{{ 'Logs' | translate }}</h5>
    <pre #logs class="message logs">{{ realtimeLogs }}</pre>
  </div>

  <div *ngIf="job?.state === JobState.Running">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between baseline"
      fxLayoutGap="8px"
    >
      <span>{{ job.description ? job.description : job.method }}</span>
      <small *ngIf="!hideProgressValue">
        {{ progressTotalPercent || 0 | number: '1.2-2' }}%
      </small>
    </div>

    <mat-progress-bar
      color="primary"
      class="entity-job-progress"
      [value]="progressTotalPercent"
      [mode]="progressTotalPercent ? 'determinate' : 'indeterminate'"
    ></mat-progress-bar>
  </div>

  <div *ngIf="showHttpProgress">
    <div
      fxLayout="row-reverse"
      fxLayoutAlign="space-between baseline"
      fxLayoutGap="8px"
    >
      <small>{{ uploadPercentage || 0 | number: '1.2-2' }}%</small>
    </div>
    <mat-progress-bar
      color="primary"
      class="entity-job-progress"
      [value]="uploadPercentage"
      [mode]="uploadPercentage > 0 ? 'determinate' : 'indeterminate'"
    >
    </mat-progress-bar>
  </div>

  <div
    *ngIf="description"
    class="entity-job-description"
    [innerHTML]="description"
  ></div>

  <div *ngIf="!description && !job">
    <mat-progress-bar
      color="primary"
      mode="indeterminate"
      class="entity-job-progress"
    >
    </mat-progress-bar>
  </div>

  <div *ngIf="!description">
    <span *ngIf="altMessage" [innerHTML]="altMessage"></span>
    <div *ngIf="!altMessage">
      <span *ngIf="job?.state === JobState.Waiting">
        {{ 'Waiting' | translate }}
      </span>
      <span *ngIf="job?.state !== JobState.Waiting">
        {{ 'Fetching data...' | translate }}
      </span>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <ng-container
    *ngIf="job?.state === JobState.Failed; then buttonsFailed; else buttonsDefault"
  ></ng-container>

  <ng-template #buttonsFailed>
    <button
      class="mat-dialog-close"
      mat-icon-button
      mat-dialog-close="close"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="CLOSE"
    >
      <mat-icon>close</mat-icon>
    </button>
  </ng-template>

  <ng-template #buttonsDefault>
    <button
      *ngIf="showAbortButton && job?.state === JobState.Running"
      mat-button
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="ABORT"
      (click)="abortJob()"
    >
      {{ 'Abort' | translate }}
    </button>

    <button
      *ngIf="showCloseButton"
      class="mat-dialog-close"
      mat-icon-button
      mat-dialog-close="close"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="CLOSE"
    >
      <mat-icon>remove</mat-icon>
    </button>
  </ng-template>
</div>
