<div cdkScrollable class={{id}} [ngClass]="id !== slideInModalId ? 'jw-modal' : ''">
  <div class="jw-modal-body">
    <div class="slidein-title-bar" fxLayout="row">
      <div fxFlex="90%" fxLayout="row" fxLayoutAlign="start center">
        <h3 class="formtitle">{{ title | translate }}</h3>
      </div>
      <mat-icon fxFlex="10%" id="close-icon" (click)="close()">cancel</mat-icon>
    </div>
    <ng-container *ngIf="!wizard; else wizardBlock">
      <ng-container *appLet="asFormConfig(conf) as formConf">
        <ng-container *ngIf="formOpen && formConf && (!formConf.formType || formConf.formType === 'EntityFormComponent')">
          <ix-entity-form *ngIf="formConf.fieldSets" class="slidein-entity-form" [conf]="formConf"></ix-entity-form>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #wizardBlock>
      <ix-entity-wizard *ngIf="formOpen" class="slidein-entity-form" [conf]="asWizardConfig(conf)"></ix-entity-wizard>
    </ng-template>
  </div>
</div>
<div class="jw-modal-background {{id}}-background" (click)="close()"></div>
