<h1 matDialogTitle>{{ 'Change Password' | translate }}</h1>
<form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
  <ix-input
    formControlName="currentPassword"
    type="password"
    [label]="'Current Password' | translate"
    [required]="true"
  ></ix-input>

  <ix-input
    formControlName="password"
    type="password"
    [label]="'New Password' | translate"
    [required]="true"
    [tooltip]="tooltips.password | translate"
  ></ix-input>

  <ix-input
    formControlName="passwordConfirmation"
    type="password"
    [label]="'Confirm Password' | translate"
    [required]="true"
  ></ix-input>

  <div class="form-actions">
    <button mat-button type="submit" color="primary" [disabled]="form.invalid">
      {{ 'Save' | translate }}
    </button>

    <button mat-button type="button" matDialogClose>
      {{ 'Cancel' | translate }}
    </button>
  </div>
</form>
