<ix-modal-header
  [title]="'Add Catalog' | translate"
  [loading]="isFormLoading"
></ix-modal-header>

<mat-card>
  <mat-card-content>
    <form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
      <ix-fieldset>
        <ix-input
          formControlName="label"
          [label]="'Catalog Name' | translate"
          [required]="true"
          [tooltip]="tooltips.label | translate"
        ></ix-input>

        <ix-checkbox
          formControlName="force"
          [label]="'Force Create' | translate"
          [tooltip]="tooltips.force | translate"
        ></ix-checkbox>

        <ix-input
          formControlName="repository"
          [label]="'Repository' | translate"
          [tooltip]="tooltips.repository | translate"
          [required]="true"
        ></ix-input>

        <ix-chips
          formControlName="preferred_trains"
          [label]="'Preferred Trains' | translate"
          [tooltip]="tooltips.preferred_trains | translate"
        ></ix-chips>

        <ix-input
          formControlName="branch"
          [label]="'Branch' | translate"
          [tooltip]="tooltips.branch | translate"
        ></ix-input>
      </ix-fieldset>

      <div class="form-actions">
        <button
          mat-button
          type="submit"
          color="primary"
          [disabled]="form.invalid || isFormLoading"
        >
          {{ 'Save' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
