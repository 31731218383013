<ix-label
  *ngIf="label || tooltip"
  [label]="label"
  [tooltip]="tooltip"
  [required]="required"
></ix-label>

<div class="input-container" [class.disabled]="isDisabled">
  <input
    #addressInput
    type=""
    matInput
    [required]="required"
    [disabled]="isDisabled"
    [value]="address"
    (input)="onAddressInput(addressInput)"
    (blur)="onTouched()"
  />
  <div class="slash">/</div>
  <mat-select
    class="select"
    [value]="netmask"
    (selectionChange)="onNetmaskChange($event)"
  >
    <mat-option *ngFor="let option of netmaskOptions" [value]="option.value">
      {{ option.label | translate }}
    </mat-option>
  </mat-select>
</div>
<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>
