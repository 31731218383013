<div
  *ngIf="!config['isHidden']"
  id="config.name"
  class="dynamic-field"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
>
  <div class="top">
    <label>{{ config.placeholder | translate }}</label>
    <ix-tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
      [style.margin-left.px]="5"
    ></ix-tooltip>
  </div>

  <mat-selection-list
    ix-auto
    ix-auto-type="selection-list"
    ix-auto-identifier="config.name"
    class="form-selection-list"
    id="{{ config.name }}_selectionlist"
    [formControlName]="config.name"
    [fxLayout]="selectionListLayout"
    (selectionChange)="onChangeSelectedItems($event)"
  >
    <ng-container *ngFor="let option of config.options">
      <mat-list-option
        checkboxPosition="before"
        fxFlex="100%"
        color="primary"
        class="form-checkbox"
        ix-auto
        ix-auto-type="list-option"
        id="{{ config.name }}_{{ option.value }}_listoption"
        ix-auto-identifier="{{ config.name }}_{{ option.label }}"
        [value]="option.value"
        [fxFlex.gt-md]="listOptionFlex"
      >
        {{ option.label | translate }}
      </mat-list-option>

      <ix-tooltip
        *ngIf="option.tooltip"
        class="list-option-tooltip"
        [header]="config.placeholder"
        [message]="option.tooltip"
      ></ix-tooltip>
    </ng-container>
  </mat-selection-list>

  <mat-error *ngIf="config['hasErrors']">
    <div [innerHTML]="config['errors']"></div>
  </mat-error>

  <mat-error *ngIf="config.warnings">
    <div [innerHTML]="config.warnings | translate"></div>
  </mat-error>
</div>
