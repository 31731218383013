<mat-dialog-content>
  <div
    class="jobs-header"
    fxLayout="row"
    fxLayoutAlign="space-between baseline"
    fxLayoutGap="8px"
  >
    <h3>{{ 'Jobs' | translate }}</h3>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
      <div
        class="job-badge running"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="4px"
        [matTooltip]="'Active' | translate"
      >
        <span class="job-badge-count">{{ runningJobsCount$ | async }}</span>
        <mat-icon class="job-icon-running">sync</mat-icon>
      </div>
      <div
        class="job-badge waiting"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="4px"
        [matTooltip]="'Waiting' | translate"
      >
        <span class="job-badge-count">{{ waitingJobsCount$ | async }}</span>
        <mat-icon class="job-icon-waiting">schedule</mat-icon>
      </div>
      <div
        class="job-badge failed"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="4px"
        [matTooltip]="'Failed' | translate"
      >
        <span class="job-badge-count">{{ failedJobsCount$ | async }}</span>
        <mat-icon class="job-icon-failed">cancel</mat-icon>
      </div>
    </div>
  </div>

  <div *ngIf="isLoading$ | async; else loaded">
    <mat-progress-bar
      class="loading-indicator"
      color="primary"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>

  <div *ngIf="error$ | async as error" class="error">
    <mat-icon class="icon" color="accent">cancel</mat-icon>
    <h4 class="message">
      <span class="error-word">{{ 'Error' | translate }}:</span>
      {{ error }}
    </h4>
  </div>

  <ng-template #loaded>
    <div *appLet="availableJobs$ | async as jobs">
      <div *ngIf="jobs.length > 0" class="jobs-list">
        <ix-job-item
          *ngFor="let job of jobs; trackBy: trackByJobId"
          [job]="job"
          [clickable]="true"
          (aborted)="onAbort(job)"
          (opened)="openEntityJobDialog(job)"
        ></ix-job-item>
      </div>

      <div *ngIf="!jobs.length" class="no-jobs">
        <ix-icon name="info"></ix-icon>
        <h4 class="message">{{ 'There are no tasks.' | translate }}</h4>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-button
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="HISTORY"
    (click)="goToJobs()"
  >
    {{ 'History' | translate }}
  </button>
</mat-dialog-actions>
