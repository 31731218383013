<div class="toolbar-menu">
  <div>
    <!-- TRIGGER -->
    <button
      mat-button
      color="default"
      ix-auto
      ix-auto-type="button"
      class="menu-toggle"
      [matMenuTriggerFor]="menu"
      [ix-auto-identifier]="id + '_' + config.label"
    >
      {{ config.label }} <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" multiple overlapTrigger="false">
      <!-- SELECT ALL -->
      <div (click)="$event.stopPropagation()">
        <button
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          [ix-auto-identifier]="id + '_SELECT_ALL'"
          (click)="checkAll()"
        >
          <span>
            <mat-icon *ngIf="checkLength()">check_circle</mat-icon>
            <mat-icon *ngIf="!checkLength()">remove</mat-icon>
          </span>
          <span>Select All</span>
        </button>
      </div>

      <!-- OPTIONS-->
      <div (click)="$event.stopPropagation()">
        <button
          *ngFor="let option of config.options; let i = index"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          [ix-auto-identifier]="id + '_' + option.label"
          (click)="onClick(option, i)"
        >
          <span>
            <mat-icon *ngIf="selectStates[i]">check_circle</mat-icon>
            <mat-icon *ngIf="!selectStates[i]">remove</mat-icon>
          </span>
          <span>{{ option.label | translate }}</span>
          <span *ngIf="option.labelIcon" class="icon-suffix {{ option.labelIconType }}">
            &nbsp;
            <mat-icon *ngIf="!option.labelIconType || option.labelIconType === 'default'">
              {{ option.labelIcon }}
            </mat-icon>
            <mat-icon
              *ngIf="option.labelIconType && option.labelIconType === 'community'"
              fontSet="mdi-set"
              fontIcon="mdi-{{ option.labelIcon }}"
            ></mat-icon>
            <mat-icon
              *ngIf="option.labelIconType && option.labelIconType === 'custom'"
              [svgIcon]="option.labelIcon"
            ></mat-icon>
          </span>
        </button>
      </div>
    </mat-menu>
  </div>
</div>
