<div
  *ngIf="!config['isHidden']"
  ix-auto
  ix-auto-type="combobox"
  class="dynamic-field form-select form-element form-combobox"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <div>
    <div *ngIf="config.placeholder || config.tooltip" class="label-container">
      <label class="label input-select">
        {{ config.placeholder | translate }}
        <span *ngIf="config.required">*</span>
      </label>

      <ix-tooltip
        *ngIf="config.tooltip"
        [header]="config.placeholder"
        [message]="config.tooltip"
      ></ix-tooltip>
    </div>

    <mat-form-field *ngIf="config.inputType !== 'file'">
      <div class="input-container">
        <span *ngIf="config.searchable" class="searchable">
          <mat-icon>search</mat-icon>
        </span>
        <input
          #input
          matInput
          list="options"
          [ngStyle]="{
            'padding-left': config.searchable ? '2rem' : '',
            'padding-right': hasValue() ? '2rem' : ''
          }"
          [type]="config.inputType ? config.inputType : 'text'"
          [placeholder]="config.placeholder | translate"
          [attr.value]="config.value"
          [formControlName]="config.name"
          [readonly]="config.readonly"
          [required]="config.required"
          [matAutocomplete]="autoComplete"
          (keyup)="search(input.value)"
        />
        <span *ngIf="hasValue() && !config.readonly" class="reset-input">
          <mat-icon
            fontSet="mdi-set"
            fontIcon="mdi-close-circle"
            (click)="resetInput()"
          ></mat-icon>
        </span>
      </div>
      <mat-autocomplete
        #autoComplete="matAutocomplete"
        (opened)="onOpenDropdown()"
      >
        <mat-option
          *ngFor="let option of config.searchOptions"
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="{{ option.label }}"
          class="{{ option.sticky ? 'sticky-' + option.sticky : '' }}"
          [value]="option.value"
          [class.text-wrap]="config.enableTextWrapForOptions"
        >
          <span>{{ option.label | translate }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span
      ix-auto
      ix-auto-type="button"
      id="{{ config.name }}-select"
      ix-auto-identifier="{{ config.name }}"
      [matMenuTriggerFor]="options"
      (menuOpened)="onOpenMenu()"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </span>
    <mat-menu #options [class]="'combo-box-menu'">
      <ng-container *ngFor="let option of config.options">
        <button
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          class="{{ option.sticky ? 'sticky-' + option.sticky : '' }}"
          ix-auto-identifier="{{ option.label }}"
          [class.text-wrap]="config.enableTextWrapForOptions"
          (click)="onChangeOption(option.value)"
        >
          <span>{{ option.label | translate }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <div class="margin-for-error">
    <ix-form-errors
      [control]="group.controls[config.name]"
      [config]="config"
    ></ix-form-errors>

    <mat-error *ngIf="config['hasErrors']">
      <div [innerHTML]="config['errors']"></div>
    </mat-error>

    <mat-error *ngIf="config.warnings">
      <div [innerHTML]="config.warnings | translate"></div>
    </mat-error>
  </div>
</div>
