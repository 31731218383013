<ix-label
  *ngIf="label || tooltip"
  [label]="label"
  [tooltip]="tooltip"
  [required]="required"
></ix-label>

<div class="input-container" [class.disabled]="isDisabled">
  <mat-chip-list
    #chipList
    class="form-chip"
    [disabled]="isDisabled"
    [required]="required"
  >
    <mat-chip
      *ngFor="let item of values"
      class="chip"
      [selectable]="true"
      [removable]="!isDisabled"
      (removed)="onRemove(item)"
    >
      {{ item }}
      <mat-icon *ngIf="!isDisabled" matChipRemove>cancel</mat-icon>
    </mat-chip>

    <input
      #chipInput
      [placeholder]="placeholder"
      [disabled]="isDisabled"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (blur)="onInputBlur($event)"
      (matChipInputTokenEnd)="onAdd($event.value)"
    />
  </mat-chip-list>

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onAdd($event.option.value)"
  >
    <ng-container *ngIf="suggestions$">
      <mat-option
        *ngFor="let suggestion of suggestions$ | async"
        [value]="suggestion"
      >
        {{ suggestion }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
