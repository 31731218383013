<h1 mat-dialog-title>
  <mat-icon class="information-icon">{{ icon }}</mat-icon>
  <span [innerHTML]="title | translate"></span>
</h1>
<div mat-dialog-content>
  <div *ngIf="!isHtml" class="info-panel">
    <div class="textarea" readonly matInput>{{ info | translate }}</div>
  </div>
  <div *ngIf="isHtml" class="info-panel ip-overflow-auto">
    <span [innerHtml]="info"></span>
  </div>
</div>
<div mat-dialog-actions>
  &nbsp;
  <span fxFlex></span>
  <button
    mat-button
    class="mat-button mat-accent"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="CLOSE"
    (click)="dialogRef.close(true)"
  >
    {{ 'Close' | translate }}
  </button>
</div>
