<div
  class="ix-slidein-title-bar"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <h3 class="ix-formtitle">{{ title | translate }}</h3>
  <mat-icon *ngIf="!disableClose" id="ix-close-icon" (click)="slideInService.close()">
    cancel
  </mat-icon>
</div>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
