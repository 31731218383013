<h1 mat-dialog-title id="err-title">
  <mat-icon class="warning-icon">error</mat-icon>
  {{ title | translate }}
</h1>
<div mat-dialog-content id="err-md-content">
  <div id="err-message-wrapper"><span [innerHTML]="message"></span></div>
  <div
    *ngIf="backtrace"
    class="more-info"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="backtrace-toggle"
    (click)="toggleOpen()"
  >
    <mat-icon *ngIf="isCloseMoreInfo">add_circle_outline</mat-icon>
    <mat-icon *ngIf="!isCloseMoreInfo">remove_circle_outline</mat-icon>
    <span>{{ 'More info...' | translate }}</span>
  </div>
  <div
    *ngIf="backtrace"
    id="err-bt-panel"
    class="backtrace-panel"
    [class.open]="!isCloseMoreInfo"
  >
    <div class="textarea scrolled-down" id="err-bt-text" readonly matInput>
      {{ 'Error' | translate }}: {{ backtrace }}
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="mat-button mat-accent"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="CLOSE"
    (click)="dialogRef.close(true)"
  >
    {{ 'Close' | translate }}
  </button>
  <button
    *ngIf="logs"
    mat-button
    class="mat-button mat-primary"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="DOWNLOAD LOGS"
    (click)="downloadLogs()"
  >
    {{ 'Download Logs' | translate }}
  </button>
</div>
