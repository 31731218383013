<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-permissions form-element"
  ix-auto
  ix-auto-type="permissions"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [class.has-tooltip]="config.tooltip"
  [ngClass]="fieldShow"
  [attr.id]="config.name"
>
  <div class="top">
    <label>
      {{ config.placeholder | translate }}
      <span *ngIf="config.required">*</span>
      &nbsp;&nbsp;
    </label>

    <ix-tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip | translate"
    ></ix-tooltip>

    <mat-form-field class="full-width display-none">
      <input
        matInput
        ix-auto
        ix-auto-type="input"
        ix-auto-identifier="{{ config.placeholder }}"
        [type]="config.inputType ? config.inputType : 'text'"
        [placeholder]="config.placeholder | translate"
        [attr.value]="config.value"
        [formControlName]="config.name"
      />
    </mat-form-field>
  </div>
  <table>
    <tr>
      <td></td>
      <td>{{ 'Read' | translate }}</td>
      <td>{{ 'Write' | translate }}</td>
      <td>{{ 'Execute' | translate }}</td>
    </tr>
    <tr>
      <td>
        {{ 'User' | translate }}
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_ownerRead"
          [checked]="ownerRead"
          (change)="toggleOwnerRead()"
        ></mat-checkbox>
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_ownerWrite"
          [checked]="ownerWrite"
          (change)="toggleOwnerWrite()"
        ></mat-checkbox>
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_ownerExec"
          [checked]="ownerExec"
          (change)="toggleOwnerExec()"
        ></mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        {{ 'Group' | translate }}
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_groupRead"
          [checked]="groupRead"
          (change)="toggleGroupRead()"
        ></mat-checkbox>
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_groupWrite"
          [checked]="groupWrite"
          (change)="toggleGroupWrite()"
        ></mat-checkbox>
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_groupExec"
          [checked]="groupExec"
          (change)="toggleGroupExec()"
        ></mat-checkbox>
      </td>
    </tr>
    <tr *ngIf="!config.hideOthersPermissions">
      <td>
        {{ 'Other' | translate }}
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_otherRead"
          [checked]="otherRead"
          (change)="toggleOtherRead()"
        ></mat-checkbox
        ><br />
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_otherWrite"
          [checked]="otherWrite"
          (change)="toggleOtherWrite()"
        ></mat-checkbox
        ><br />
      </td>
      <td>
        <mat-checkbox
          color="primary"
          ix-auto
          ix-auto-type="checkbox"
          ix-auto-identifier="{{ config.name }}_otherExec"
          [checked]="otherExec"
          (change)="toggleOtherExec()"
        ></mat-checkbox>
      </td>
    </tr>
  </table>

  <mat-error *ngIf="config['hasErrors']">{{ config['errors'] }}</mat-error>
</div>
