<button
  name="Power"
  mat-icon-button
  class="topbar-button-right"
  ix-auto
  ix-auto-type="button"
  ix-auto-identifier="power"
  [matTooltip]="tooltips.power | translate"
  [matMenuTriggerFor]="powerMenu"
>
  <mat-icon>power_settings_new</mat-icon>
</button>
<mat-menu #powerMenu="matMenu">
  <button name="power-log-out" mat-menu-item (click)="onSignOut()">
    <mat-icon>exit_to_app</mat-icon>
    {{ 'Log Out' | translate }}
  </button>

  <button name="power-restart" mat-menu-item (click)="onReboot()">
    <mat-icon>replay</mat-icon>
    {{ 'Restart' | translate }}
  </button>

  <button name="power-shut-down" mat-menu-item (click)="onShutdown()">
    <mat-icon>power_settings_new</mat-icon>
    {{ 'Shut Down' | translate }}
  </button>
</mat-menu>
