<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-textarea"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <mat-form-field class="full-width">
    <input
      #textAreaSsh
      type="text"
      matInput
      [placeholder]="config.placeholder | translate"
      [formControlName]="config.name"
      [ngClass]="config.class"
      [required]="config.required"
    />
  </mat-form-field>
  <mat-card-actions>
    <button
      mat-button
      color="primary"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="{{ config.customEventActionLabel | uppercase }}"
      (click)="customEventMethod($event)"
    >
      {{ config.customEventActionLabel }}
    </button>
  </mat-card-actions>

  <ix-tooltip
    *ngIf="config.tooltip"
    [header]="config.placeholder"
    [message]="config.tooltip"
  ></ix-tooltip>

  <mat-error *ngIf="config['hasErrors']">
    <div [innerHTML]="config['errors']"></div>
  </mat-error>

  <mat-error *ngIf="config.warnings">
    <div [innerHTML]="config.warnings | translate"></div>
  </mat-error>
</div>
