<div
  *ngIf="!config['isHidden']"
  class="form-task"
  ix-auto
  ix-auto-type="task"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [attr.id]="config.name"
>
  <label class="form-task-label">{{ config.placeholder | translate }}</label>
  <br />
  <mat-card>
    <mat-tab-group #tabGroup (selectChange)="onSelectChange()">
      <mat-tab
        *ngFor="let field of config.tabs"
        ix-auto
        ix-auto-type="tab"
        [label]="field.tabName | translate"
        ix-auto-identifier="{{ field.tabName }}"
      >
        <ng-container
          dynamicField
          [config]="field"
          [group]="tabFormGroup"
          [fieldShow]="'show'"
        ></ng-container>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
