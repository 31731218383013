<div class="full-width container has-tooltip">
  <ix-label
    *ngIf="label || tooltip"
    [label]="label"
    [tooltip]="tooltip"
    [required]="required"
  ></ix-label>

  <div class="input-container" [class.disabled]="isDisabled">
    <mat-select
      [required]="required"
      [disabled]="isDisabled"
      [(ngModel)]="crontab"
      (blur)="onTouched()"
      (ngModelChange)="onChange($event)"
    >
      <mat-option
        *ngFor="let preset of defaultPresets"
        class="schedule-option"
        [value]="preset.value"
      >
        {{ preset.label }}
        <span class="crontab">({{ preset.value }})</span>&nbsp;
        <span class="preset-description">{{ preset.description }}</span>
      </mat-option>
      <mat-option
        class="schedule-option"
        [value]="customCrontab"
        (click)="onCustomOptionSelected()"
      >
        {{ 'Custom' | translate }}
        <span *ngIf="customCrontab" class="crontab">({{ customCrontab }})</span>
        <span class="preset-description">
          <ng-container *ngIf="customCrontab; else customPrompt">
            {{ customCrontab | crontabExplanation }}
          </ng-container>
          <ng-template #customPrompt>
            {{ 'Create custom schedule' | translate }}
          </ng-template>
        </span>
      </mat-option>
    </mat-select>
  </div>
  <ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>
</div>
