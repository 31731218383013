<div
  class="form-element dynamic-field form-select {{ config.inlineLabel ? 'inline-label' : 'not' }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [hidden]="config.isHidden"
  [attr.id]="config.name"
>
  <div *ngIf="config.placeholder || config.tooltip" class="label-container">
    <ng-container *ngIf="config.inlineLabel">
      <div class="label half-width"></div>
    </ng-container>
    <label class="label input-select">
      {{ config.placeholder | translate }}
      <span *ngIf="config.required">*</span>
    </label>
    <ix-tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
    ></ix-tooltip>

    <a *ngIf="config.linkText" class="link" (click)="linkClicked()">
      {{ config.linkText | translate }}
      <mat-icon style="font-size: x-small">open_in_new</mat-icon>
    </a>
  </div>

  <ng-container *ngIf="config.inlineLabel">
    <div class="label half-width">{{ config.inlineLabel }}</div>
  </ng-container>
  <mat-form-field
    #field
    value="fromFormField"
    [ngClass]="{
      'full-width': !config.inlineLabel,
      'half-width': config.inlineLabel
    }"
  >
    <mat-spinner
      *ngIf="config.isLoading"
      diameter="40"
      class="form-select-spinner"
      id="{{ config.name }}_form-select-spinner"
    >
    </mat-spinner>
    <mat-select
      #selectTrigger
      ix-auto
      ix-auto-type="select"
      ix-auto-identifier="{{ config.placeholder }}"
      [value]="formValue"
      [formControlName]="config.name"
      [multiple]="config.multiple"
      [required]="config.required"
      (selectionChange)="onChangeOption($event)"
    >
      <mat-select-trigger *ngIf="config.multiple">
        <span *ngFor="let value of customTriggerValue; last as isLast">
          {{ value }}{{ isLast ? '' : ', ' }}
        </span>
      </mat-select-trigger>

      <ng-container *ngIf="formReady">
        <ng-container *ngFor="let option of config.options; let i = index">
          <!-- if disabled -->
          <mat-option
            *ngIf="option.disable && !option.hiddenFromDisplay"
            disabled
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ config.placeholder }}_{{ option.label }}"
            [value]="option.value"
          >
            <span *ngIf="config.multiple">
              <mat-icon *ngIf="selectStates[i]" class="fn-checked">
                check_circle
              </mat-icon>
              <mat-icon *ngIf="!selectStates[i]" class="fn-unchecked">
                remove
              </mat-icon>
            </span>
            {{ option.label | translate }}
          </mat-option>

          <!-- else -->
          <mat-option
            *ngIf="!option?.disable"
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ config.placeholder }}_{{ option.label }}"
            [value]="option.value"
            [class.text-wrap]="config.enableTextWrapForOptions"
            (click)="onToggleSelect(option)"
          >
            <span *ngIf="config.multiple">
              <mat-icon *ngIf="selectStates[i]" class="fn-checked">
                check_circle
              </mat-icon>
              <mat-icon *ngIf="!selectStates[i]" class="fn-unchecked">
                remove
              </mat-icon>
            </span>
            {{ option.label | translate }}

            <ix-tooltip
              *ngIf="option.tooltip"
              class="option-tooltip"
              [message]="option.tooltip"
            ></ix-tooltip>
          </mat-option>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!formReady || config.options.length === 0">
        <ng-container>
          <mat-option
            disabled
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ config.placeholder }}_zeroState"
            [class.text-wrap]="config.enableTextWrapForOptions"
          >
            {{ config.zeroStateMessage ? config.zeroStateMessage : '--' }}
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="margin-for-error">
    <ix-form-errors
      [control]="group.controls[config.name]"
      [config]="config"
    ></ix-form-errors>

    <mat-error *ngIf="config['hasErrors']">
      <div [innerHTML]="config['errors']"></div>
    </mat-error>

    <mat-error *ngIf="config.warnings">
      <div [innerHTML]="config.warnings | translate"></div>
    </mat-error>
  </div>
</div>
