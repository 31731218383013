<div class="settings-column">
  <form [formGroup]="form">
    <section>
      <h4>{{ 'Presets' | translate }}</h4>
      <ix-select
        class="preset-select"
        formControlName="preset"
        [options]="presetOptions$"
      ></ix-select>
    </section>

    <section>
      <h4>
        {{ config.hideMinutes ? ('Hours' | translate) : ('Minutes/Hours' | translate) }}
        <ix-tooltip [message]="tooltips.general | translate"></ix-tooltip>
      </h4>
      <div class="no-spaces-hint">
        ({{ 'Separate values with commas, and without spaces.' | translate }})
      </div>

      <ix-input
        *ngIf="!config.hideMinutes"
        class="input"
        formControlName="minutes"
        [required]="true"
        [label]="'Minutes' | translate"
        [tooltip]="tooltips.minutes | translate"
      ></ix-input>

      <ix-input
        class="input"
        formControlName="hours"
        [required]="true"
        [label]="'Hours' | translate"
        [tooltip]="tooltips.hours | translate"
      ></ix-input>
    </section>

    <section>
      <h4>{{ 'Days' | translate }}</h4>

      <ix-input
        class="input days-of-month-input"
        formControlName="days"
        [required]="true"
        [label]="'Days of Month' | translate"
        [tooltip]="tooltips.days | translate"
      ></ix-input>

      <div
        *ngIf="hasOrConditionExplanation$ | async"
        class="or-condition-explanation"
      >
        <div class="line"></div>

        <div class="or-label">
          {{ 'or' | translate }}
          <ix-tooltip [message]="tooltips.orTooltip | translate"></ix-tooltip>
        </div>
        <div class="line"></div>
      </div>
      <h5 class="days-of-week-header">{{ 'Days of Week' | translate }}</h5>

      <div class="weekdays" formGroupName="weekdays">
        <mat-checkbox
          *ngFor="let weekday of weekdays"
          color="primary"
          class="weekend"
          [formControlName]="weekday.value"
        >
          {{ weekday.label }}
        </mat-checkbox>
      </div>
    </section>

    <section>
      <h4>{{ 'Months' | translate }}</h4>

      <div class="months" formArrayName="months">
        <mat-checkbox
          *ngFor="let month of months"
          color="primary"
          class="month"
          [formControlName]="month.value"
        >
          {{ month.label }}
        </mat-checkbox>
      </div>
    </section>

    <div class="form-actions">
      <button
        mat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="onDone()"
      >
        {{ 'Done' | translate }}
      </button>
    </div>
  </form>
</div>
<ix-scheduler-preview-column
  [crontab]="crontab"
  [timezone]="timezone"
  [startTime]="config.startTime"
  [endTime]="config.endTime"
></ix-scheduler-preview-column>
