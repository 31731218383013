<mat-card>
  <mat-spinner
    *ngIf="showSpinner"
    id="entity-spinner"
    [diameter]="40"
  ></mat-spinner>

  <form
    #entityForm="ngForm"
    [formGroup]="formGroup"
    (keydown.enter)="$event.preventDefault()"
  >
    <mat-vertical-stepper
      #stepper
      formArrayName="formArray"
      [linear]="conf?.isLinear"
      (selectionChange)="selectionChange($event)"
    >
      <div *ngFor="let step of conf.wizardConfig; let i = index">
        <mat-step
          *ngIf="!step.skip"
          #matStepInstance
          formGroupName="{{ i }}"
          [stepControl]="formArray?.get([i])"
        >
          <ng-template matStepLabel>{{ step.label | translate }}</ng-template>
          <div class="form-wrap">
            <ng-container *ngFor="let fieldSet of step.fieldSets; let j = index">
              <div
                fxLayout="row wrap"
                fxLayoutAlign="start start"
                fxFlex="100%"
                class="fieldset divider-{{ fieldSet.divider }}"
                fxFlex.gt-xs="calc({{ fieldSet.width ? fieldSet.width : '100%' }} - 16px)"
                [ngClass]="[
                  fieldSet.class ? fieldSet.class : '',
                  isFieldsetAvailabel(fieldSet) ? '' : 'empty-fieldset'
                ]"
              >
                <mat-divider *ngIf="fieldSet.divider && i > 0"></mat-divider>

                <h4 *ngIf="fieldSet.label && isFieldsetAvailabel(fieldSet)" class="fieldset-label">
                  {{ fieldSet.name | translate }}
                </h4>

                <div
                  *ngFor="let field of fieldSet.config; let k = index"
                  fxFlex="100%"
                  fxFlex.gt-xs="calc({{ field.width ? field.width : '100%'}} - 16px)"
                  id="{{ k }}"
                  [ngClass]="field.class === 'inline' ? 'form-inline' : 'form-line'"
                >
                  <div
                    dynamicField
                    id="dynamicField_{{ field.name }}"
                    [config]="field"
                    [group]="formArray?.get([i]) | cast"
                    [fieldShow]="isShow(field.name) ? 'show' : 'hide'"
                  ></div>
                </div>
              </div>
            </ng-container>
          </div>

          <mat-card-actions
            *ngIf="conf.summary || i !== conf.wizardConfig.length - 1; else lastStepAction"
            class="wizard-action"
          >
            <button
              *ngIf="!conf.hideCancel"
              class="btn btn-block btn-lg btn-primary"
              mat-button
              color="accent"
              type="button"
              ix-auto
              ix-auto-type="button"
              id="cancel_button_{{ i }}"
              ix-auto-identifier="CANCEL_{{ step.label }}"
              (click)="goBack()"
            >
              {{ 'Cancel' | translate }}
            </button>

            <button
              *ngIf="i !== 0"
              mat-button
              matStepperPrevious
              color="accent"
              type="button"
              ix-auto
              ix-auto-type="button"
              id="goback_button_{{ i }}"
              ix-auto-identifier="BACK_{{ step.label }}"
            >
              {{ 'Back' | translate }}
            </button>

            <button
              *ngIf="!conf.customNext"
              mat-button
              matStepperNext
              color="primary"
              type="button"
              ix-auto
              ix-auto-type="button"
              id="goforward_button_{{ i }}"
              ix-auto-identifier="NEXT_{{ step.label }}"
              [disabled]="showSpinner"
              (click)="handleNext(matStepInstance)"
            >
              {{ 'Next' | translate }}
            </button>

            <button
              *ngIf="conf.customNext"
              mat-button
              color="primary"
              type="button"
              ix-auto
              ix-auto-type="button"
              id="custom_button_{{ i }}"
              ix-auto-identifier="{{ customNextText | uppercase }}"
              (click)="handleNext(matStepInstance)"
            >
              {{ customNextText | translate }}
            </button>

            <span *ngFor="let custBtn of conf.customActions">
              <button
                *ngIf="!conf.isCustomActionVisible || conf.isCustomActionVisible(custBtn.id, i)"
                mat-button
                color="primary"
                type="button"
                ix-auto
                ix-auto-type="button"
                id="cust_button_{{ custBtn.name }}"
                ix-auto-identifier="{{ custBtn.name | uppercase }}"
                (click)="custBtn['function']()"
              >
                {{ custBtn.name | translate }}
              </button>
            </span>
          </mat-card-actions>

          <ng-template #lastStepAction>
            <mat-card-actions class="wizard-action">
              <button
                *ngIf="!conf.hideCancel"
                id="secondary_cancel_button"
                class="btn btn-block btn-lg btn-primary"
                mat-button
                color="accent"
                ix-auto
                ix-auto-type="button"
                ix-auto-identifier="CANCEL_secondary"
                (click)="goBack()"
              >
                {{ 'Cancel' | translate }}
              </button>

              <button
                id="noconfirm_button"
                mat-button
                matStepperPrevious
                color="accent"
                ix-auto
                ix-auto-type="button"
                ix-auto-identifier="BACK_noconfirm"
              >
                {{ 'Back' | translate }}
              </button>

              <button
                id="confirm_button"
                mat-button
                color="primary"
                ix-auto
                ix-auto-type="button"
                ix-auto-identifier="{{ saveSubmitText | uppercase }}"
                [disabled]="!entityForm.form.valid"
                (click)="onSubmit()"
              >
                {{ saveSubmitText | translate }}
              </button>
            </mat-card-actions>
          </ng-template>
        </mat-step>
      </div>

      <mat-step *ngIf="conf.summary">
        <ng-template matStepLabel>
          {{ 'Confirm Options' | translate }}
        </ng-template>

        <div>{{ conf.summaryTitle }}</div>

        <ix-wizard-summary
          [fieldConfigs]="summaryFieldConfigs"
          [value]="summaryValue | cast"
          [isRoot]="true"
          [summary]="conf.summary"
        ></ix-wizard-summary>

        {{ 'Confirm these settings.' | translate }}

        <mat-card-actions class="wizard-action">
          <button
            *ngIf="!conf.hideCancel"
            id="secondary_cancel_button"
            class="btn btn-block btn-lg btn-primary"
            mat-button
            color="accent"
            ix-auto
            ix-auto-type="button"
            ix-auto-identifier="CANCEL_secondary"
            (click)="goBack()"
          >
            {{ 'Cancel' | translate }}
          </button>

          <button
            id="noconfirm_button"
            mat-button
            matStepperPrevious
            color="accent"
            ix-auto
            ix-auto-type="button"
            ix-auto-identifier="BACK_noconfirm"
          >
            {{ 'Back' | translate }}
          </button>

          <button
            id="confirm_button"
            mat-button
            color="primary"
            ix-auto
            ix-auto-type="button"
            ix-auto-identifier="{{ saveSubmitText | uppercase }}"
            [disabled]="!entityForm.form.valid"
            (click)="onSubmit()"
          >
            {{ saveSubmitText | translate }}
          </button>
        </mat-card-actions>
      </mat-step>
    </mat-vertical-stepper>
  </form>
</mat-card>
