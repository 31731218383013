<ix-modal-header
  [title]="'Kubernetes Settings' | translate"
  [loading]="isFormLoading"
></ix-modal-header>
<mat-card>
  <mat-card-content>
    <form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
      <ix-fieldset>
        <ix-select
          formControlName="node_ip"
          [label]="'Node IP' | translate"
          [tooltip]="tooltips.node_ip | translate"
          [options]="nodeIpOptions$"
        ></ix-select>

        <ix-select
          formControlName="route_v4_interface"
          [label]="'Route v4 Interface' | translate"
          [tooltip]="tooltips.route_v4_interface | translate"
          [options]="routeInterfaceOptions$"
        ></ix-select>

        <ix-input
          formControlName="route_v4_gateway"
          [label]="'Route v4 Gateway' | translate"
          [tooltip]="tooltips.route_v4_gateway"
        ></ix-input>

        <ix-checkbox
          formControlName="enable_container_image_update"
          [label]="'Enable Container Image Updates' | translate"
        ></ix-checkbox>

        <ix-checkbox
          formControlName="configure_gpus"
          [label]="'Enable GPU support' | translate"
        ></ix-checkbox>

        <ix-checkbox
          formControlName="servicelb"
          [label]="'Enable Integrated Loadbalancer' | translate"
        ></ix-checkbox>

        <ix-checkbox
          formControlName="validate_host_path"
          [label]="'Enable Host Path Safety Checks' | translate"
        ></ix-checkbox>
      </ix-fieldset>
      <ix-fieldset [title]="'Settings Requiring Re-Initialization' | translate">
        <p class="help-text">
          {{ reInitHelpText | translate }}
        </p>

        <ix-input
          formControlName="cluster_cidr"
          [label]="'Cluster CIDR' | translate"
          [tooltip]="tooltips.cluster_cidr"
          [required]="true"
        ></ix-input>

        <ix-input
          formControlName="service_cidr"
          [label]="'Service CIDR' | translate"
          [tooltip]="tooltips.service_cidr"
          [required]="true"
        ></ix-input>

        <ix-input
          formControlName="cluster_dns_ip"
          [label]="'Cluster DNS IP' | translate"
          [tooltip]="tooltips.cluster_dns_ip"
          [required]="true"
        ></ix-input>

        <ix-checkbox
          formControlName="force"
          [label]="'Force' | translate"
        ></ix-checkbox>
      </ix-fieldset>

      <div class="form-actions">
        <button
          mat-button
          type="submit"
          color="primary"
          [disabled]="form.invalid || isFormLoading"
        >
          {{ 'Save' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
