<section *ngIf="this.columns?.length" class="wrapper__details">
  <div
    *ngFor="let column of this.columns"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <h4>{{ column.name | translate }}:</h4>
    <div fxLayoutAlign="start center" fxLayoutGap="4px">
      <ng-container *ngIf="column?.widget?.component; else notScheduleList">
        <mat-icon
          class="widget-icon"
          fontSet="mdi-set"
          fontIcon="mdi-{{ column.widget.icon }}"
          [matMenuTriggerFor]="widget"
        ></mat-icon>
        <mat-menu #widget="matMenu">
          <!-- column widgets need to be registered with this switch case -->
          <ng-container [ngSwitch]="column.widget.component">
            <ix-task-schedule-list
              *ngSwitchCase="'TaskScheduleListComponent'"
              [value]="getColumnValue(column) | cast"
              [config]="config"
              [parent]="parent"
            ></ix-task-schedule-list>
          </ng-container>
        </mat-menu>
        <p>
          {{ config.enabled ? getColumnValue(column, true) : getColumnValue(column) }}
        </p>
      </ng-container>
      <ng-template #notScheduleList>
        <p>{{ getColumnValue(column) }}</p>
      </ng-template>
    </div>
  </div>
</section>

<mat-divider *ngIf="this.columns?.length"></mat-divider>

<div
  *ngIf="parent.hasActions"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
  class="wrapper__action-buttons"
>
  <div class="wrap-buttons-container">
    <ng-container *ngFor="let action of actions">
      <button
        *ngIf="!parent.conf.isActionVisible || parent.conf.isActionVisible.bind(parent.conf)(action.id, this.config)"
        mat-button
        ix-auto-type="button"
        ix-auto-identifier="{{ action?.name | uppercase }}_{{ action.id }}"
        id="action_button_{{ action?.name }}__{{ action.id }}"
        [disabled]="action.disabled"
        [ix-auto]="config.name | cast"
        (click)="action.onClick(this.config)"
      >
        <div fxLayoutAlign="center center" fxLayoutGap="8px">
          <mat-icon>{{ action.icon }}</mat-icon>
          <p
            [matTooltip]="action.matTooltip"
            [matTooltipDisabled]="!action.disabled"
            [matTooltipPosition]="action.ttposition ? action.ttposition : 'below'"
          >
            {{ action.label | translate }}
          </p>
        </div>
      </button>
    </ng-container>
  </div>
</div>
