<ng-template #pageHeader>
  <ix-page-title-header>
    <ix-search-input (search)="onSearch($event)"></ix-search-input>

    <button mat-button [matMenuTriggerFor]="menu" [disabled]="filteredChartItems.length === 0">
      {{ 'Bulk Actions' | translate }}
      <ix-icon name="mdi-menu-down" class="menu-caret"></ix-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button *ngIf="!areAllAppsSelected" mat-menu-item (click)="$event.stopPropagation(); onSelectAll()">
        <ix-icon name="mdi-check-circle-outline"></ix-icon>
        <span>{{ 'Select All' | translate }}</span>
      </button>
      <button *ngIf="areAllAppsSelected" mat-menu-item (click)="$event.stopPropagation(); onUnselectAll()">
        <ix-icon name="mdi-check-circle"></ix-icon>
        <span>{{ 'Unselect All' | translate }}</span>
      </button>
      <button mat-menu-item [disabled]="!isSomethingSelected" (click)="onBulkStart()">
        <ix-icon name="mdi-play"></ix-icon>
        <span>{{ 'Start' | translate }}</span>
      </button>
      <button mat-menu-item [disabled]="!isSomethingSelected" (click)="onBulkStop()">
        <ix-icon name="mdi-stop"></ix-icon>
        <span>{{ 'Stop' | translate }}</span>
      </button>
      <button mat-menu-item [disabled]="!hasUpdates" (click)="onBulkUpgrade()">
        <ix-icon name="mdi-update"></ix-icon>
        <span>{{ 'Upgrade' | translate }}</span>
      </button>
      <button mat-menu-item [disabled]="!isSomethingSelected" (click)="onBulkDelete()">
        <ix-icon name="mdi-delete"></ix-icon>
        <span>{{ 'Delete' | translate }}</span>
      </button>
    </mat-menu>

    <ix-common-apps-toolbar-buttons></ix-common-apps-toolbar-buttons>
  </ix-page-title-header>
</ng-template>

<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card *ngFor="let item of filteredChartItems" class="chart-item">
    <div class="content-wrapper" fxLayout="row">
      <div class="logo-container">
        <div class="logo">
          <img
            [src]="item.chart_metadata.icon"
            [src-fallback]="imagePlaceholder"
          />
        </div>
        <div
          class="badge-area"
          [class.badge-official]="item.catalog === officialCatalog"
        >
          <div class="capitalized-text">
            {{ item.catalog }}
          </div>
          <div class="train-badge capitalized-text">
            {{ item.catalog_train }}
          </div>
        </div>
        <mat-checkbox
          color="primary"
          class="bulk-checkbox"
          [(ngModel)]="item.selected"
        ></mat-checkbox>
      </div>
      <div class="content-box" (click)="showChartEvents(item.name)">
        <div class="content">
          <strong
            class="chart-name"
            textLimiter
            threshold="20"
            content="{{ item.name }}"
          ></strong>
          <div>
            <span
              class="version"
              textLimiter
              threshold="50"
              content="{{ item.human_version }}"
            ></span>
          </div>
          <div *ngIf="item.update_available || item.container_images_update_available; else elseBlock">
            <span class="update-label" matTooltip="Available version: {{ item.human_latest_version }}">
              {{ 'Update available' | translate }}
            </span>
          </div>
          <ng-template #elseBlock>
            <div class="update-label">
              {{ 'Up to date' | translate }}
            </div>
          </ng-template>
        </div>

        <div
          class="chart-button-row"
          fxLayout="row"
          fxLayoutAlign="end end"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="item.status === ChartReleaseStatus.Active">
            <div class="ports">
              <span
                class="ports-text"
                textLimiter
                threshold="28"
                content="{{ appService.getPorts(item) | lowercase }}"
              >
              </span>
            </div>
            <div *ngFor="let portal of item.portals | keyvalue">
              <button
                mat-button
                ix-auto
                ix-auto-type="button"
                ix-auto-identifier="{{ item.name }}-portal"
                (click)="portalLink(item, portal.key)"
              >
                {{ portalName(portal.key) }}
              </button>
            </div>
          </ng-container>
          <button
            *ngIf="item.status !== ChartReleaseStatus.Stopped"
            mat-button
            ix-auto
            ix-auto-type="button"
            ix-auto-identifier="{{ item.name }}-stop"
            (click)="stop(item.name)"
          >
            {{ 'Stop' | translate }}
          </button>
          <button
            *ngIf="item.status === ChartReleaseStatus.Stopped"
            mat-button
            ix-auto
            ix-auto-type="button"
            ix-auto-identifier="{{ item.name }}-start"
            (click)="start(item.name)"
          >
            {{ 'Start' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="more-info">
      <span
        class="status"
        [ngClass]="item.status === ChartReleaseStatus.Active ? 'active' : 'other'"
      >
        {{ item.status }}
        <ng-container *ngIf="item.pod_status.desired > 1">
          {{ item.pod_status.available }}/{{ item.pod_status.desired }}
        </ng-container>
      </span>
      <button
        name="chart-actions"
        mat-icon-button
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="chart-actions"
        [matMenuTriggerFor]="actionsMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button
          name="update"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="update"
          [disabled]="!item.update_available && !item.container_images_update_available"
          (click)="update(item.name)"
        >
          <mat-icon>call_made</mat-icon>
          <span>{{ 'Upgrade' | translate }}</span>
        </button>
        <button
          name="roll-back"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="rollback"
          [disabled]="!item.history || isEmpty(item.history)"
          (click)="onRollback(item.name)"
        >
          <mat-icon>replay</mat-icon>
          <span>{{ 'Roll Back' | translate }}</span>
        </button>
        <button
          name="edit"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="edit"
          (click)="edit(item.name)"
        >
          <mat-icon>edit</mat-icon>
          <span>{{ 'Edit' | translate }}</span>
        </button>
        <button
          name="shell"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="shell"
          (click)="openShell(item.name)"
        >
          <mat-icon
            fontSet="mdi-set"
            fontIcon="mdi-console-line"
            class="shell-icon"
          ></mat-icon>
          <span>{{ 'Shell' | translate }}</span>
        </button>
        <button
          name="logs"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="shell"
          (click)="openLogs(item.name)"
        >
          <mat-icon>description</mat-icon>
          <span>{{ 'Logs' | translate }}</span>
        </button>
        <button
          name="delete"
          mat-menu-item
          ix-auto
          ix-auto-type="option"
          ix-auto-identifier="delete"
          (click)="delete(item.name)"
        >
          <mat-icon>delete</mat-icon>
          <span>{{ 'Delete' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </mat-card>

  <ix-entity-empty
    *ngIf="filteredChartItems.length === 0"
    [conf]="emptyPageConf"
  ></ix-entity-empty>
</div>
