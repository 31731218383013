<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-input form-element"
  ix-auto
  ix-auto-type="file-uploader"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <div class="top">
    <label>{{ config.placeholder | translate }}</label>
    <ix-tooltip
      *ngIf="config.tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
      [style.margin-left.px]="5"
    ></ix-tooltip>
  </div>

  <div>
    <button
      type="button"
      mat-button
      color="accent"
      class="btn-default"
      (click)="fileBtnClick()"
    >
      {{ 'Choose File' | translate }}
    </button>
    <div *ngIf="fileList" class="mt-1">
      <div *ngFor="let file of fileList">
        {{ file.name }}
      </div>
    </div>
  </div>

  <div *ngIf="config.hideButton; else showButton">
    <mat-card-content class="display-none">
      <input
        *ngIf="!config.multiple; else multipleFiles"
        #fileInput
        id="fb"
        type="file"
        accept="{{ config.acceptedFiles }}"
        [formControlName]="config.name"
        (change)="upload(config.fileLocation)"
      />
      <ng-template #multipleFiles>
        <input
          #fileInput
          id="fb"
          type="file"
          multiple
          accept="{{ config.acceptedFiles }}"
          [formControlName]="config.name"
          (change)="upload(config.fileLocation)"
        />
      </ng-template>
    </mat-card-content>
    <mat-error *ngIf="config['hasErrors']">
      <div [innerHTML]="config['errors'] | translate"></div>
    </mat-error>
    <mat-error *ngIf="config.warnings">
      <div [innerHTML]="config.warnings | translate"></div>
    </mat-error>
  </div>
  <ng-template #showButton>
    <mat-card-content class="display-none">
      <input
        #fileInput
        id="fb"
        type="file"
        accept="{{ config.acceptedFiles }}"
        [formControlName]="config.name"
      />
    </mat-card-content>
    <mat-card-actions class="buttons">
      <button
        mat-button
        type="button"
        color="primary"
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="UPLOAD"
        (click)="upload(config.fileLocation)"
      >
        {{ 'Upload' | translate }}
      </button>
    </mat-card-actions>

    <mat-error *ngIf="config['hasErrors']">
      <div [innerHTML]="config['errors'] | translate"></div>
    </mat-error>

    <mat-error *ngIf="config.warnings">
      <div [innerHTML]="config.warnings | translate"></div>
    </mat-error>
  </ng-template>
</div>
