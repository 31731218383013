<button mat-button [matMenuTriggerFor]="menu">
  {{ 'Settings' | translate }}
  <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
</button>

<mat-menu #menu="matMenu" overlapTrigger="false">
  <button mat-menu-item (click)="onChoosePool()">
    {{ 'Choose Pool' | translate }}
  </button>
  <button mat-menu-item (click)="onAdvancedSettings()">
    {{ 'Advanced Settings' | translate }}
  </button>
  <button *ngIf="wasPoolSet" mat-menu-item (click)="onUnsetPool()">
    {{ 'Unset Pool' | translate }}
  </button>
</mat-menu>

<button
  mat-button
  color="primary"
  [disabled]="!wasPoolSet"
  (click)="onLaunchDockerImage()"
>
  {{ 'Launch Docker Image' | translate }}
</button>
