<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-input colorpicker"
  ix-auto
  ix-auto-type="colorpicker"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [id]="config.name"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
>
  <div
    class="color-swatch"
    [style.background-color]="colorProxy"
    (click)="togglePicker()"
  ></div>
  <mat-form-field class="full-width">
    <input
      #input
      matInput
      [placeholder]="config.placeholder | translate"
      [value]="colorProxy"
      (input)="colorProxy = input.value"
    />

    <input
      matInput
      cpOutputFormat="hex"
      cpPosition="bottom"
      cpWidth="180px"
      class="picker-input"
      [cpToggle]="picker"
      [cpPositionRelativeToArrow]="true"
      [formControlName]="config.name"
      [(colorPicker)]="colorProxy"
      (cpInputChange)="inputListener('cpInputChange', $event)"
      (colorPickerChange)="cpListener('cpInputChange', $event)"
    />
  </mat-form-field>

  <ix-tooltip
    *ngIf="config.tooltip"
    [header]="config.placeholder"
    [message]="config.tooltip | translate"
  ></ix-tooltip>
  <mat-error *ngIf="config['hasErrors']">{{ config['errors'] }}</mat-error>
</div>
