<fieldset>
  <div *ngIf="title" class="title-container">
    <legend class="title">
      {{ title }}
    </legend>

    <ix-tooltip
      *ngIf="tooltip"
      class="tooltip"
      [header]="title"
      [message]="tooltip"
    ></ix-tooltip>
  </div>

  <ng-content></ng-content>

  <mat-divider *ngIf="divider" class="divider" inset></mat-divider>
</fieldset>
