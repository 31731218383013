<div
  class="ix-{{ id }} ix-slide-in-form"
  [class.open]="isSlideInOpen"
  [class.wide]="wide"
>
  <div class="ix-slide-in-body">
    <ng-template #body></ng-template>
  </div>
</div>

<div
  class="ix-slide-in-background ix-{{ id }}-background"
  [class.open]="isSlideInOpen"
  (click)="close()"
></div>
