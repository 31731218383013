<!-- START OF CONTROLS SECTION -->
<div
  *ngIf="entity && conf"
  fxFlex
  class="entity-table-controls"
  fxLayout="row wrap"
  fxLayoutAlignGap="16px"
  fxLayoutAlign="end center"
>
  <form class="form-element search-field" id="row-filter">
    <mat-form-field floatPlaceholder="never">
      <span matPrefix class="search-icon-wrapper">
        <mat-icon>search</mat-icon>
      </span>
      <input
        #filter
        matInput
        ix-auto
        ix-auto-type="input"
        autocomplete="off"
        placeholder="{{ 'Filter' | translate }} {{ entity.title | translate }}"
        ix-auto-identifier="Filter {{ conf.title }}"
        [value]="filterValue"
      />
      <span
        class="reset-icon-wrapper"
        matSuffix
        [class.invisible]="!filterValue || filterValue.length === 0"
      >
        <mat-icon
          fontSet="mdi-set"
          fontIcon="mdi-close-circle"
          (click)="resetFilter()"
        ></mat-icon>
      </span>
    </mat-form-field>
  </form>

  <div *ngIf="conf && conf.customActions">
    <span *ngFor="let custBtn of conf.customActions">
      <button
        *ngIf="!conf.isCustomActionVisible || conf.isCustomActionVisible(custBtn.id)"
        mat-button
        ix-auto
        ix-auto-type="button"
        type="button"
        color="default"
        id="cust_button_{{ custBtn.name }}"
        ix-auto-identifier="{{ custBtn.name }}"
        (click)="custBtn['function']()"
      >
        {{ custBtn.name | translate }}
      </button>
    </span>
  </div>

  <!-- START COLUMN FILTER -->
  <div *ngIf="entity.columnFilter && entity.allColumns && entity.allColumns.length > 0">
    <button
      mat-button
      color="default"
      class="menu-toggle"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="{{ conf.title }}_COLUMNS"
      [matMenuTriggerFor]="menu"
    >
      {{ 'Columns' | translate }}
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" multiple overlapTrigger="false">
      <!-- SELECT ALL -->
      <div (click)="$event.stopPropagation()">
        <button
          mat-menu-item
          id="check-all"
          ix-auto
          ix-auto-type="action"
          ix-auto-identifier="COLUMNS_SELECT"
          (click)="entity.checkAll()"
        >
          <span>
            <mat-icon *ngIf="entity.checkLength()">check_circle</mat-icon>
            <mat-icon *ngIf="!entity.checkLength()">remove</mat-icon>
          </span>
          <span *ngIf="!entity.checkLength()">
            {{ 'Select All' | translate }}
          </span>
          <span *ngIf="entity.checkLength()">
            {{ 'Unselect All' | translate }}
          </span>
        </button>
      </div>

      <!-- INDIVIDUAL COLUMNS-->
      <div (click)="$event.stopPropagation()">
        <button
          *ngFor="let col of entity.allColumns"
          mat-menu-item
          ix-auto
          ix-auto-type="action"
          id="menu_option-{{ col.name }}"
          ix-auto-identifier="COLUMNS_{{ col.name }}"
          (click)="entity.toggle(col)"
        >
          <span>
            <mat-icon *ngIf="entity.isChecked(col)">check_circle</mat-icon>
            <mat-icon *ngIf="!entity.isChecked(col)">remove</mat-icon>
          </span>
          <span>{{ col.name | translate }}</span>
        </button>
      </div>

      <div (click)="$event.stopPropagation()">
        <button
          mat-menu-item
          id="reset_col_view"
          ix-auto
          ix-auto-type="action"
          ix-auto-identifier="COLUMNS_Reset to Defaults"
          (click)="entity.resetColViewToDefaults()"
        >
          <span>
            <mat-icon>undo</mat-icon>
          </span>
          <span>{{ 'Reset to Defaults' | translate }}</span>
        </button>
      </div>
    </mat-menu>
  </div>
  <!-- END COLUMN FILTER -->

  <!-- OLD TEMPLATE START -->
  <div *ngIf="this.totalActions > 0" class="entity-add-actions-wrapper">
    <button
      *ngIf="this.totalActions > 1; else elseBlock"
      mat-button
      color="primary"
      class="menu-toggle"
      ix-auto
      ix-auto-type="button"
      matTooltip="{{ this.menuTriggerMessage | translate }}"
      ix-auto-identifier="{{ entity.conf.title }}_ACTIONS"
      [matMenuTriggerFor]="menu"
    >
      {{ 'Actions' | translate }}
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" overlapTrigger="false">
      <button
        *ngIf="this.entity.conf.routeAdd || this.entity.conf.doAdd"
        mat-menu-item
        id="add_action_button"
        ix-auto
        ix-auto-type="button"
        ix-auto-identifier="{{ entity.conf.title }}_ADD"
        (click)="this.entity.doAdd()"
      >
        <span>{{ 'Add' | translate }}</span>
      </button>
      <button
        *ngFor="let action of actions"
        mat-menu-item
        ix-auto
        ix-auto-type="button"
        id="add_action_button_{{ action?.label }}"
        ix-auto-identifier="{{ entity.conf.title }}_{{ action.label }}"
        (click)="action.onClick()"
      >
        <span>{{ action.label | translate }}</span>
      </button>
    </mat-menu>

    <ng-template #elseBlock>
      <!--When there is no routeAdd -->
      <button
        *ngIf="this.entity.conf.routeAdd || this.entity.conf.doAdd"
        mat-button
        color="primary"
        id="add_action_button"
        ix-auto
        ix-auto-type="button"
        matTooltip="{{ this.entity.conf.routeAddTooltip | translate }}"
        ix-auto-identifier="{{ entity.conf.title }}_ADD"
        [disabled]="entity.conf.addBtnDisabled"
        (click)="this.entity.doAdd()"
      >
        {{ 'Add' | translate }}
      </button>
      <button
        *ngIf="!(this.entity.conf.routeAdd || this.entity.conf.doAdd) && this.totalActions === 1"
        mat-button
        color="primary"
        ix-auto
        ix-auto-type="button"
        id="add_action_button_{{ actions[0]?.label }}"
        matTooltip="{{ actions[0].label | translate }}"
        ix-auto-identifier="{{ entity.conf.title }}_{{ this.actions[0].label }}"
        (click)="this.actions[0].onClick()"
      >
        {{ this.actions[0].label | translate }}
      </button>
    </ng-template>
  </div>
  <!-- OLD TEMPLATE END -->

  <div *ngIf="conf.globalConfig" id="config">
    <button
      mat-icon-button
      ix-auto
      ix-auto-type="settings"
      id="{{ conf.globalConfig.id }}"
      ix-auto-identifier="{{ entity.title }}"
      [matTooltip]="conf.globalConfig.tooltip"
      (click)="conf.globalConfig.onClick()"
    >
      <mat-icon *ngIf="conf.globalConfig.icon; else settingsIcon">
        {{ conf.globalConfig.icon }}
      </mat-icon>
      <ng-template #settingsIcon><mat-icon>settings</mat-icon></ng-template>
    </button>
  </div>

  <mat-spinner
    *ngIf="!entity.showDefaults && entity.showSpinner"
    #entityspinner
    id="entity-spinner"
    class="spinner"
    [diameter]="40"
  >
  </mat-spinner>

  <!-- END OF CONTROLS SECTION -->
</div>
