<ng-template #pageHeader>
  <ix-page-title-header>
    <ix-search-input (search)="onSearch($event)"></ix-search-input>

    <button mat-button (click)="doAdd()">{{ 'Pull Image' | translate }}</button>

    <ix-common-apps-toolbar-buttons></ix-common-apps-toolbar-buttons>
  </ix-page-title-header>
</ng-template>

<ix-entity-empty
  *ngIf="isLoading$ | async; else loaded"
  [conf]="loadingConfig"
></ix-entity-empty>

<ng-template #loaded>
  <div *ngIf="checkboxColumn?.selection.selected.length" class="bulk-actions-toolbar">
    <div class="title">
      <strong>{{ 'Bulk Operations' | translate }}</strong>
    </div>

    <div class="actions">
      <button
        mat-button
        [disabled]="!selectionHasUpdates"
        (click)="doUpdate(checkboxColumn?.selection.selected)"
      >
        <mat-icon>update</mat-icon>
        {{ 'Update' | translate }}
      </button>
      <button mat-button (click)="doDelete(checkboxColumn?.selection.selected)">
        <mat-icon>delete</mat-icon>
        {{ 'Delete' | translate }}
      </button>
    </div>
  </div>

  <table
    ix-table
    matSort
    matSortDisableClear="true"
    [dataSource]="dataSource"
    [matSortActive]="defaultSort.active"
    [matSortDirection]="defaultSort.direction"
    [fixedLayout]="true"
  >
    <ix-checkbox-column></ix-checkbox-column>

    <ng-container matColumnDef="repo_tags">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{ 'Tags' | translate }}
      </th>
      <td
        *matCellDef="let image; dataSource: dataSource"
        mat-cell
        [title]="image.repo_tags"
      >
        {{ image.repo_tags.join(', ') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{ 'Image ID' | translate }}
      </th>
      <td
        *matCellDef="let image; dataSource: dataSource"
        mat-cell
        [title]="image.id"
      >
        {{ image.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{ 'Date Created' | translate }}
      </th>
      <td *matCellDef="let dockerImage; dataSource: dataSource" mat-cell>
        {{ dockerImage.created.$date | formatDateTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{ 'Image Size' | translate }}
      </th>
      <td *matCellDef="let image; dataSource: dataSource" mat-cell>
        {{ image.size ? formatter.convertBytesToHumanReadable(image.size) : ('Unknown' | translate) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{ 'State' | translate }}
      </th>
      <td *matCellDef="let image; dataSource: dataSource" mat-cell>
        {{
          image.update_available ? ('Update available' | translate) : ('Up to date' | translate)
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let image; dataSource: dataSource" mat-cell>
        <button
          mat-icon-button
          [title]="'Settings' | translate"
          [matMenuTriggerFor]="actionsMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <a
            mat-menu-item
            [disabled]="!image.update_available"
            (click)="doUpdate([image])"
          >
            {{ image.update_available ? ('Update available' | translate) : ('Up to date' | translate) }}
          </a>
          <a mat-menu-item (click)="doDelete([image])">
            {{ 'Delete' | translate }}
          </a>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr
      *matRowDef="let element; columns: displayedColumns; dataSource: dataSource"
      mat-row
    ></tr>

    <tr *matNoDataRow class="mat-row no-data-row">
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <ix-entity-empty [conf]="emptyOrErrorConfig$ | async"></ix-entity-empty>
      </td>
    </tr>
  </table>

  <ix-table-paginator
    [dataSource]="dataSource"
    [pageSize]="10"
  ></ix-table-paginator>
</ng-template>
