<div
  class="panel_container action-length-{{ actions.length }}"
  [style.paddingTop]="inlineActions ? 0 : '4px'"
>
  <ng-container *ngIf="actions && isSingleAction && singleAction">
    <button
      *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(actions[0].actions[0].id, row)"
      mat-button
      ix-auto-type="button"
      id="action_{{ singleAction.name }}__{{ singleAction.id }}"
      ix-auto-identifier="{{ row.name }}_{{ singleAction.id }}_{{ singleAction.name }}"
      [disabled]="singleAction.disabled"
      [ix-auto]=""
      [color]="actions[0].actions[0].color ? actions[0].actions[0].color : 'default'"
      (click)="singleAction.onClick(this.row)"
    >
      {{ actions[0].actions[0].label }}
    </button>
  </ng-container>

  <ng-container
    *ngIf="!isSingleAction && !inlineActions; else inlineActionsTemplate"
  >
    <!-- Material Design Icon Set -->
    <button
      *ngIf="iconName.startsWith('mdi-')"
      class="transparent-btn"
      [attr.aria-label]="'Actionable Options' | translate"
      [matMenuTriggerFor]="appMenu"
      (click)="noPropagate($event)"
    >
      <mat-icon
        fontSet="mdi-set"
        id="{{ row[entity?.conf?.rowIdentifier || 'name'] }}_{{ action }}_button"
        [fontIcon]="iconName"
        [style.cursor]="'pointer'"
        [style.font-size]="'24px'"
      ></mat-icon>
    </button>

    <!-- Standard Material Icon Set -->
    <button
      *ngIf="!iconName.startsWith('mdi-')"
      class="transparent-btn"
      [attr.aria-label]="'Actionable Options' | translate"
      [matMenuTriggerFor]="appMenu"
      (click)="noPropagate($event)"
    >
      <mat-icon
        id="{{ row[entity?.conf?.rowIdentifier || 'name'] }}_{{ action }}_button"
        [style.cursor]="'pointer'"
      >
        {{ iconName }}
      </mat-icon>
    </button>

    <mat-menu #appMenu="matMenu">
      <span *ngIf="!groups; else groupMenu">
        <span
          *ngFor="let action of actions"
          [matTooltip]="action.matTooltip"
          [matTooltipDisabled]="!action.disabled"
          [matTooltipPosition]="action.ttposition ? action.ttposition : 'left'"
        >
          <button
            *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(action.id, row)"
            ix-auto-type="action"
            mat-menu-item
            id="action_button_{{ action?.name }}__{{ action.id }}"
            ix-auto-identifier="{{ action.id }}_{{ action.label }}"
            [disabled]="action.disabled"
            [ix-auto]=""
            (click)="action.onClick(this.row)"
          >
            <span>{{ action?.label | translate }}</span>
          </button>
        </span>
      </span>
      <ng-template #groupMenu>
        <span *ngFor="let group of actions">
          <div
            *ngIf="group.actions && group.actions.length > 0"
            class="group-menu-header"
          >
            <mat-divider></mat-divider>
            <div class="title">
              <strong>{{ group?.title | translate }}</strong>
            </div>
          </div>
          <span
            *ngFor="let action of group.actions"
            [matTooltip]="action.matTooltip"
            [matTooltipDisabled]="!action.disabled"
            [matTooltipPosition]="action.ttposition ? action.ttposition : 'left'"
          >
            <button
              *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(action.id, row)"
              mat-menu-item
              ix-auto-type="action"
              id="action_button_{{ action?.name }}__{{ action.id }}"
              ix-auto-identifier="{{ action.id }}_{{ action.label }}"
              [disabled]="action.disabled"
              [ix-auto]=""
              (click)="action.onClick(this.row)"
            >
              <span>{{ action?.label | translate }}</span>
            </button>
          </span>
        </span>
      </ng-template>
    </mat-menu>
  </ng-container>

  <ng-template #inlineActionsTemplate>
    <ng-container *ngFor="let action of actions">
      <button
        *ngIf="!entity.conf.isActionVisible || entity.conf.isActionVisible.bind(entity.conf)(action.id, row)"
        mat-icon-button
        ix-auto-type="action"
        id="action_button_{{ action?.name }}__{{ action.id }}"
        ix-auto-identifier="{{ action.id }}_{{ action.name | uppercase }}"
        [disabled]="action.disabled"
        [ix-auto]=""
        [matTooltip]="action.label | translate"
        [matTooltipPosition]="action.ttposition ? action.ttposition : 'left'"
        (click)="action.onClick(this.row)"
      >
        <mat-icon>{{ action.icon }}</mat-icon>
      </button>
    </ng-container>
  </ng-template>
</div>
