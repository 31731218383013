<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-explorer form-element"
  ix-auto
  ix-auto-type="explorer"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <div class="label-container">
    <label>
      {{ config.placeholder | translate }}
      <span *ngIf="config.required">*</span>
    </label>
    <ix-tooltip
      *ngIf="config.tooltip"
      class="formexinput-tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
    ></ix-tooltip>
  </div>

  <div class="tbody">
    <div class="form-ex-flex-container tr" id="form-ex-flex-container">
      <div id="box2" class="td">
        <mat-form-field class="full-width" id="formexinput">
          <textarea
            *ngIf="config.multiple; else textInput"
            #autosize="cdkTextareaAutosize"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="4"
            [placeholder]="config.placeholder | translate"
            [formControlName]="config.name"
            [required]="config.required"
            [disabled]="shouldBeDisabled()"
          ></textarea>
          <ng-template #textInput>
            <input
              matInput
              type="text"
              ix-auto
              ix-auto-type="input"
              ix-auto-identifier="{{ config.name }}"
              [placeholder]="config.placeholder | translate"
              [formControlName]="config.name"
              [required]="config.required"
              [disabled]="shouldBeDisabled()"
              [readonly]="shouldBeDisabled()"
            />
          </ng-template>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="tree-container">
    <tree-root
      *ngIf="!shouldBeDisabled()"
      #tree
      id="box3"
      [nodes]="nodes"
      [options]="customTemplateStringOptions"
      [focused]="true"
      (select)="onClick($event)"
      (deselect)="onClick($event)"
      (toggleExpanded)="onToggle($event)"
      (loadNodeChildren)="loadNodeChildren($event)"
    >
      <ng-template
        *ngIf="
          config.explorerType === ExplorerType.Directory ||
          config.explorerType === ExplorerType.Dataset ||
          config.explorerType === ExplorerType.File ||
          !config.explorerType
        "
        #treeNodeTemplate
        let-node
      >
        <mat-icon *ngIf="node.data.hasChildren || node.data.children; else fileIcon">
          folder
        </mat-icon>
        <ng-template #fileIcon>
          <mat-icon>insert_drive_file</mat-icon>
        </ng-template>
        <span title="{{ node.data.name }}">
          {{ node.data.subTitle }}
          <span
            *ngIf="node.data['acl']"
            class="dataset-subtitle"
            matTooltipPosition="right"
            matTooltip="{{ 'An ACL is present in this path' | translate }}"
          >
            ACL
          </span>
        </span>
      </ng-template>
    </tree-root>
  </div>
  <ix-form-errors
    [control]="group.controls[config.name]"
    [config]="config"
  ></ix-form-errors>

  <mat-error *ngIf="config['hasErrors']">
    <div [innerHTML]="config['errors']"></div>
  </mat-error>

  <mat-error *ngIf="config.warnings">
    <div [innerHTML]="config.warnings | translate"></div>
  </mat-error>
</div>
