<ng-template #pageHeader>
  <ix-page-title-header>
    <ix-search-input (search)="onSearch($event)"></ix-search-input>

    <button mat-button (click)="syncAll()">
      {{ 'Refresh All' | translate }}
    </button>

    <ix-toolbar-multimenu
      *ngIf="catalogMenu"
      [config]="catalogMenu"
      (selectionChange)="onCatalogsSelectionChanged($event)"
    ></ix-toolbar-multimenu>

    <ix-common-apps-toolbar-buttons></ix-common-apps-toolbar-buttons>
  </ix-page-title-header>
</ng-template>

<div fxLayout="column" fxFill>
  <div
    class="bar"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    fxLayoutGap="30"
  >
    <div *ngFor="let job of catalogSyncJobs" class="progress-item">
      <div>{{ 'Retrieving catalog' | translate }} {{ job.name }}</div>
      <mat-progress-bar mode="buffer" [value]="job.progress"></mat-progress-bar>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-card *ngFor="let item of filteredCatalogApps" class="catalog-item">
      <div
        class="content-wrapper"
        fxLayout="row"
        (click)="showSummaryDialog(item.name, item.catalog.id, item.catalog.train)"
      >
        <div class="logo-container">
          <div class="logo">
            <img [src]="item.icon_url" [src-fallback]="imagePlaceholder" />
          </div>
          <div class="badge-area" [class.badge-official]="item.catalog.label === officialCatalog">
            <div class="capitalized-text">
              {{ item.catalog.label }}
            </div>
            <div class="train-badge capitalized-text">
              {{ item.catalog.train }}
            </div>
          </div>
        </div>
        <div class="content" fxLayout="column" fxLayoutAlign="space-between center">
          <div class="catalog-title">
            <h3>{{ item.name }}</h3>
            <div class="catalog-version">{{ item.latest_human_version }}</div>
          </div>
          <div class="button-row" (click)="$event.stopPropagation()">
            <button
              *ngIf="item.healthy; else showError"
              mat-button
              ix-auto
              ix-auto-type="button"
              ix-auto-identifier="{{ item.name }}-install"
              [disabled]="!selectedPool"
              (click)="doInstall(item.name, item.catalog.id, item.catalog.train)"
            >
              {{ 'Install' | translate }}
            </button>

            <ng-template #showError>
              <div class="unhealthy-warning" [matTooltip]="item.healthy_error">
                <mat-icon class="icon">report_problem</mat-icon>
                {{ 'Unhealthy' | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card>

    <ix-entity-empty
      *ngIf="!filteredCatalogApps.length"
      [conf]="emptyPageConf"
    ></ix-entity-empty>
  </div>
</div>
