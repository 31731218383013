<button
  *ngIf="isIconShown"
  mat-icon-button
  id="dirservices-manager"
  class="topbar-button-right"
  ix-auto
  ix-auto-type="button"
  ix-auto-identifier="dirServices"
  [matTooltip]="tooltips.directory_services_monitor | translate"
  (click)="onIndicatorClicked()"
>
  <mat-icon>info</mat-icon>
</button>
