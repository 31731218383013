<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-input"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <mat-form-field class="full-width">
    <input
      matInput
      ix-auto
      ix-auto-type="datepicker"
      ix-auto-identifier="{{ config.placeholder }}"
      [matDatepicker]="picker"
      [placeholder]="config.placeholder | translate"
      [value]="config.value"
      [formControlName]="config.name"
    />
    <!-- <button matSuffix [matDatepickerToggle]="picker"></button> -->
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <ix-tooltip
    *ngIf="config.tooltip"
    [header]="config.placeholder"
    [message]="config.tooltip | translate"
  ></ix-tooltip>

  <mat-error *ngIf="config['hasErrors']">{{ config['errors'] }}</mat-error>
</div>
