<div
  *ngIf="!config['isHidden']"
  class="entity-slider"
  ix-auto
  ix-auto-type="slider"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [attr.id]="config.name"
>
  <label>{{ config.placeholder | translate }}</label>
  <mat-slider
    MatSliderChange
    class="full-width"
    thumbLabel
    [min]="config.min"
    [max]="config.max"
    [formControlName]="config.name"
    (change)="updateValue($event)"
  >
  </mat-slider>
  <label>
    <h5>
      <b>{{ value }}</b>
    </h5>
  </label>
</div>
