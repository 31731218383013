<button
  *ngIf="tcStatus && tcStatus.status === TrueCommandStatus.Connecting"
  mat-icon-button
  id="tc-connecting"
  class="topbar-button-right"
  ix-auto
  ix-auto-type="button"
  ix-auto-identifier="tcConnecting"
  [matTooltip]="tooltips.tc_connect | translate"
  (click)="stopTrueCommandConnecting()"
>
  <mat-icon
    class="truecommand-button-connecting-animation"
    svgIcon="ix:truecommand_logo_white"
  ></mat-icon>
</button>

<button
  *ngIf="tcStatus && tcStatus.status !== TrueCommandStatus.Connecting"
  mat-icon-button
  id="tc-status"
  class="topbar-button-right"
  ix-auto
  ix-auto-type="button"
  ix-auto-identifier="tcStatus"
  [matTooltip]="tooltips.tc_status | translate"
  (click)="handleClick()"
>
  <mat-icon
    svgIcon="ix:truecommand_logo_white"
    matBadgeSize="small"
    [ngClass]="['truecommand-icon', tcStatus.status]"
    [matBadge]="tcsStatusMatBadge"
  ></mat-icon>
</button>
