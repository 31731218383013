<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-textarea paragraph"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [style.width]="config.width ? config.width : 'auto'"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <div fxLayoutAlign="start start" fxLayoutGap="8px">
    <mat-icon
      *ngIf="config.paragraphIcon"
      class="paragraph-icon"
      [style.width]="config.paragraphIconSize"
      [style.height]="config.paragraphIconSize"
      [style.fontSize]="config.paragraphIconSize"
    >
      {{ config.paragraphIcon }}
    </mat-icon>
    <p
      class="full-width"
      [class.large]="config.isLargeText"
      [innerHTML]="config.paraText | translate"
    ></p>
    <ix-tooltip
      *ngIf="config.tooltip"
      class="paratext-tooltip"
      [header]="config.placeholder"
      [message]="config.tooltip"
    ></ix-tooltip>
  </div>

  <mat-error *ngIf="config['hasErrors']">{{ config['errors'] }}</mat-error>

  <mat-form-field class="full-width display-none">
    <input
      matInput
      [type]="config.inputType ? config.inputType : 'text'"
      [placeholder]="config.placeholder"
      [attr.value]="config.value"
      [formControlName]="config.name"
    />
  </mat-form-field>
</div>
