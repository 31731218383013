<div
  class="job-item"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px"
  [class.abortable]="job?.state === JobState.Running && job?.abortable"
  [class.interactive]="clickable"
>
  <div class="job-item-body" (click)="clickable && open()">
    <div fxLayout="row" fxLayoutAlign="space-between baseline" fxLayoutGap="8px">
      <span class="job-description">
        {{ job.description ? job.description : job.method }}
      </span>
      <small *ngIf="job.state === JobState.Running" class="job-progress-percent">
        {{ job.progress.percent || 0 | number: '1.2-2' }}%
      </small>
    </div>

    <mat-progress-bar
      *ngIf="job.state === JobState.Running"
      class="job-progress"
      [value]="job.progress.percent"
      [mode]="job.progress.percent ? 'determinate' : 'indeterminate'"
    ></mat-progress-bar>

    <div [ngSwitch]="job.state">
      <ng-container *ngSwitchCase="JobState.Running">
        <div *ngIf="job?.progress?.description" class="job-progress-description">
          {{ job.progress.description }}
        </div>
        <div *ngIf="!job?.progress?.description" class="job-time">
          {{ 'Started' | translate }}:
          <strong>
            {{ job.time_started?.$date ? (job.time_started.$date | formatDateTime) : '–' }}
          </strong>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="JobState.Waiting">
        <div class="job-time">
          {{ 'Waiting' | translate }}:
          <strong>
            {{ job.time_started?.$date ? (job.time_started.$date | formatDateTime) : '–' }}
          </strong>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="JobState.Failed">
        <div class="job-time">
          {{ 'Stopped' | translate }}:
          <strong>
            {{ job.time_finished?.$date ? (job.time_finished.$date | formatDateTime) : '–' }}
          </strong>
        </div>
      </ng-container>
    </div>
  </div>

  <button
    *ngIf="job.state === JobState.Running && job.abortable"
    class="job-button-abort"
    mat-icon-button
    type="button"
    (click)="abort()"
  >
    <mat-icon
      fontSet="mdi-set"
      fontIcon="mdi-close-circle"
      class="job-icon-abort"
    ></mat-icon>
  </button>

  <button
    *ngIf="job.state === JobState.Waiting"
    mat-icon-button
    type="button"
    [disabled]="true"
  >
    <mat-icon class="job-icon-waiting">schedule</mat-icon>
  </button>

  <button
    *ngIf="job.state === JobState.Failed"
    mat-icon-button
    type="button"
    [disabled]="true"
  >
    <mat-icon matTooltipPosition="left" class="job-icon-failed" [matTooltip]="job.error">cancel</mat-icon>
  </button>

  <button
    *ngIf="job.state === JobState.Success"
    mat-icon-button
    type="button"
    [disabled]="true"
  >
    <mat-icon class="job-icon-success">check_circle</mat-icon>
  </button>
</div>
