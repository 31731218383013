<div class="list-item">
  <button
    *ngIf="canDelete"
    mat-icon-button
    type="button"
    class="delete-btn"
    (click)="deleteItem()"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <ng-content></ng-content>
</div>
