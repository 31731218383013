<div
  *ngIf="!config['isHidden']"
  class="toggle-button"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [attr.id]="config.name"
>
  <label>{{ config.placeholder | translate }}</label>
  <br />
  <mat-button-toggle-group
    multiple
    class="toggle-button-wrap toggle-button-group"
  >
    <mat-button-toggle
      *ngFor="let option of config.options"
      buttonToggleGroupMultiple
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="{{ option.label }}"
      [value]="option.value"
      [checked]="option.checked"
      (change)="check(option)"
    >
      {{ option.label | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
