<div class="wrapper">
  <div class="header">
    <div class="header-overlay">
      <div class="logo-wrapper">
        <mat-icon
          svgIcon="ix:truenas_scale_logo_full"
          class="logo"
          alt="TrueNAS SCALE logo"
        ></mat-icon>
      </div>
    </div>
  </div>

  <div class="content-wrapper">
    <div class="help">{{ helptext.help | translate }}</div>
    <div class="line-item">
      <div fxFlex="10%">
        <mat-icon class="bullet-icon">assignment</mat-icon>
      </div>
      <div
        fxFlex="90%"
        class="medium-font"
        [innerHtml]="helptext.docs | translate"
      ></div>
    </div>

    <div class="line-item">
      <div fxFlex="10%"><mat-icon class="bullet-icon">group</mat-icon></div>
      <div
        fxFlex="90%"
        class="medium-font"
        [innerHtml]="helptext.forums | translate"
      ></div>
    </div>

    <div
      *appLet="systemType | mapValue: productTypeLabels as product"
      id="open-source"
      [innerHtml]="helptext.opensource | translate: { product }"
    ></div>
  </div>
</div>

<div mat-dialog-actions id="actions">
  <ix-copyright-line class="copyright-txt"></ix-copyright-line>
  <div id="button-wrapper">
    <button
      mat-button
      class="mat-button mat-primary"
      ix-auto
      ix-auto-type="button"
      ix-auto-identifier="CLOSE"
      (click)="dialogRef.close(false)"
    >
      {{ helptext.actionBtnText.about | translate | uppercase }}
    </button>
  </div>
</div>
