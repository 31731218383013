<button
  mat-icon-button
  id="task-manager"
  class="topbar-button-right"
  ix-auto
  ix-auto-type="button"
  ix-auto-identifier="taskManager"
  [matTooltip]="tooltips.task_manager | translate"
  (click)="onIndicatorPressed()"
>
  <mat-icon
    *appLet="jobBadgeCount$ | async as jobBadgeCount"
    matBadgeSize="small"
    matBadgeColor="warn"
    [matBadge]="jobBadgeCount"
    [matBadgeHidden]="jobBadgeCount === 0"
  >
    assignment
  </mat-icon>
</button>
