<h1 mat-dialog-title>{{ title | translate }}</h1>
<div mat-dialog-content id="confirm-msg">
  <span *ngIf="secondaryCheckBox">
    <mat-checkbox
      *ngIf="secondaryCheckBox"
      id="confirm-dialog__secondary-checkbox"
      class="confirm-checkbox"
      ix-auto
      color="primary"
      ix-auto-type="checkbox"
      ix-auto-identifier="{{ secondaryCheckBoxMsg | uppercase }}"
      (change)="secondaryCheckBoxEvent()"
    >
      <span id="confirm-label">{{ secondaryCheckBoxMsg | translate }}</span>
    </mat-checkbox>
    <ix-tooltip *ngIf="tooltip" [message]="tooltip"></ix-tooltip>
    <br />
    <br />
  </span>
  <div class="message-content">
    <span [innerHTML]="message | translate"></span>
  </div>
</div>

<div mat-dialog-actions>
  <mat-checkbox
    *ngIf="!hideCheckBox"
    color="primary"
    id="confirm-dialog__confirm-checkbox"
    name="confirm_checkbox"
    class="confirm-checkbox"
    ix-auto
    ix-auto-type="checkbox"
    ix-auto-identifier="CONFIRM"
    (change)="toggleSubmit($event)"
  >
    {{ 'Confirm' | translate }}
  </mat-checkbox>
  <span fxFlex></span>
  <button
    *ngIf="!hideCancel"
    id="confirm-dialog__cancel-button"
    mat-button
    name="cancel_button"
    class="mat-button mat-accent"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="{{ cancelMsg | uppercase }}"
    (click)="dialogRef.close(false)"
  >
    {{ cancelMsg | translate }}
  </button>
  <button
    id="confirm-dialog__action-button"
    mat-button
    name="ok_button"
    class="mat-button mat-primary"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="{{ buttonMsg | uppercase }}"
    [disabled]="isDisabled()"
    (click)="customSubmit ? customSubmit() : dialogRef.close(true)"
  >
    {{ buttonMsg | translate }}
  </button>
</div>
