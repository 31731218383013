<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-input form-element form-ip-netmask"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  ix-auto
  ix-auto-type="array"
  ix-auto-identifier="{{ config.placeholder }}"
  [formGroup]="group"
  [ngClass]="fieldShow"
  [class.has-pwtoggle]="config.togglePw"
  [class.has-tooltip]="config.tooltip"
  [attr.id]="config.name"
>
  <div class="label-container" fxFlex="100" fxLayoutAlign="start center">
    <label class="label input-text">{{ config.placeholder | translate }}</label>
    <span *ngIf="config.required">*</span>
    <ix-tooltip
      *ngIf="config.tooltip"
      fxFlex="48px"
      [header]="config.placeholder"
      [message]="config.tooltip"
    ></ix-tooltip>
  </div>
  <div fxFlex="100" fxLayout="row" fxLayoutGap="3px">
    <mat-form-field fxFlex="calc(100% - 72px - 16px)" class="display-none">
      <input
        matInput
        [type]="config.inputType ? config.inputType : 'text'"
        [placeholder]="config.placeholder | translate"
        [attr.value]="config.value"
        [formControlName]="config.name"
      />
    </mat-form-field>
    <mat-form-field fxFlex="calc(100% - 72px - 16px)">
      <input
        matInput
        ix-auto
        ix-auto-type="input"
        ix-auto-identifier="{{ config.placeholder }}"
        [placeholder]="config.placeholder | translate"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="config.readonly"
        [required]="config.required"
        [pattern]="network.ipv4OrIpv6CidrOptional"
        [(ngModel)]="address"
        (blur)="setAddress($event)"
      />
    </mat-form-field>
    <div fxFlex="80px" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex class="divider">/</div>
      <mat-form-field *ngIf="!config.netmaskPreset" fxFlex="64px">
        <mat-select
          ix-auto
          ix-auto-type="input"
          disableOptionCentering
          [ix-auto-identifier]="config.placeholder"
          [value]="netmask"
          [placeholder]="'Netmask' | translate"
          (selectionChange)="setNetmask($event)"
        >
          <mat-option
            *ngFor="let option of netmaskOptions"
            ix-auto
            ix-auto-type="option"
            ix-auto-identifier="{{ option.label }}"
            [value]="option.value"
          >
            {{ option.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="config.netmaskPreset" fxFlex="72px">
        <mat-select
          disableOptionCentering
          class="disabled-select"
          disabled
          ix-auto
          ix-auto-type="select"
          ix-auto-identifier="{{ config.placeholder }}"
          [value]="config.netmaskPreset"
        >
          <mat-option [value]="config.netmaskPreset">
            {{ config.netmaskPreset }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="margin-for-error">
    <mat-error *ngIf="config['hasErrors']">{{ config['errors'] }}</mat-error>
    <mat-error *ngIf="config.warnings">
      {{ config.warnings | translate }}
    </mat-error>
  </div>
</div>
