<div
  *ngIf="!config['isHidden']"
  class="dynamic-field form-element"
  [formGroup]="group"
>
  <button
    mat-button
    type="button"
    ix-auto
    ix-auto-type="button"
    ix-auto-identifier="{{ config.customEventActionLabel }}"
    [class]="config.buttonClass ? config.buttonClass : 'form-button'"
    [color]="config.buttonColor ? config.buttonColor : 'default'"
    [disabled]="config.disabled"
    (click)="config.customEventMethod($event)"
  >
    {{ config.customEventActionLabel | translate }}
  </button>
</div>
