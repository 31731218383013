<ix-label
  *ngIf="label || tooltip"
  [label]="label"
  [tooltip]="tooltip"
  [required]="required"
></ix-label>

<div
  class="input-container"
  [class.disabled]="isDisabled"
  [class.readonly]="readonly"
>
  <span *ngIf="prefixIcon" class="prefix-icon">
    <mat-icon>{{ prefixIcon }}</mat-icon>
  </span>
  <input
    #ixInput
    matInput
    [class.prefix-padding]="prefixIcon"
    [class.password-field]="isPasswordField()"
    [class.has-reset-input-icon]="shouldShowResetInput()"
    [required]="required"
    [readonly]="readonly"
    [disabled]="isDisabled"
    [type]="getType()"
    [value]="formatted"
    [autocomplete]="autocomplete"
    [placeholder]="placeholder"
    (input)="input(ixInput)"
    (focus)="focus(ixInput)"
    (blur)="blurred()"
  />
  <span *ngIf="shouldShowResetInput()" class="reset-input">
    <mat-icon
      fontSet="mdi-set"
      fontIcon="mdi-close-circle"
      (click)="resetInput(ixInput)"
    ></mat-icon>
  </span>
  <button
    *ngIf="type === 'password'"
    mat-icon-button
    type="button"
    class="toggle_pw"
    (click)="onPasswordToggled()"
  >
    <i *ngIf="showPassword" class="material-icons" matTooltip="Hide">
      visibility
    </i>
    <i *ngIf="!showPassword" class="material-icons" matTooltip="Show">
      visibility_off
    </i>
  </button>
</div>

<div *ngIf="invalid">
  <mat-error>
    <div [innerHTML]="invalidMessage()"></div>
  </mat-error>
</div>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
