<p *ngIf="!hasConf">
  Please
  <button
    mat-button
    color="primary"
    class="btn btn-link"
    type="button"
    (click)="goConf()"
  >
    Click here
  </button>
  to set up configuration first.
</p>
<mat-card class="form-card">
  <div class="mat-content">
    <mat-spinner
      *ngIf="showSpinner"
      #entityspinner
      id="entity-table-spinner"
      [diameter]="40"
    ></mat-spinner>

    <form
      #entityForm="ngForm"
      class="form-wrap"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit($event)"
    >
      <div
        *ngIf="fieldSets"
        class="fieldset-container fieldset-display-default"
      >
        <ng-container *ngFor="let fieldSet of fieldSets; let i = index">
          <div
            fxLayout="row wrap"
            fxLayoutGap.gt-sm="24px"
            fxLayoutAlign="start start"
            fxFlex="100%"
            class="entity-form fieldset divider-{{ fieldSet.divider }}"
            fxFlex.gt-xs="calc({{ fieldSets[i].width ? fieldSets[i].width : '100%' }} - 16px)"
            [ngClass]="[
              fieldSet.class ? fieldSet.class : '',
              isFieldsetAvailabel(fieldSet) ? '' : 'empty-fieldset'
            ]"
          >
            <mat-divider *ngIf="fieldSets[i].divider && i > 0"></mat-divider>
            <h4
              *ngIf="fieldSet.label && isFieldsetAvailabel(fieldSet)"
              class="fieldset-label"
            >
              {{ fieldSet.name | translate }}
            </h4>
            <div
              *ngFor="let field of fieldSet.config; let ii = index"
              fxFlex="100%"
              fxFlex.gt-xs="calc({{field.width ? field.width : '100%'}} - 16px)"
              id="{{ fieldSet.name }}-{{ ii }}"
              [ngClass]="field.class === 'inline' ? 'form-inline' : 'form-line'"
            >
              <div
                dynamicField
                id="form_field_{{ field.name }}"
                [config]="field"
                [group]="formGroup"
                [fieldShow]="isShow(field.name) ? 'show' : 'hide'"
              ></div>
            </div>
          </div>
        </ng-container>
        <mat-card-actions class="buttons">
          <button
            *ngIf="!conf.hideSaveBtn"
            id="save_button"
            class="btn btn-block btn-warning mat-primary"
            type="submit"
            mat-button
            ix-auto-type="button"
            color="primary"
            ix-auto-identifier="{{ saveSubmitText | uppercase }}"
            [disabled]="!entityForm.form.valid || !conf.saveButtonEnabled"
            [ix-auto]=""
          >
            {{ saveSubmitText | translate }}
          </button>
          <button
            *ngIf="conf.routeSuccess"
            id="goback_button"
            class="btn btn-block btn-lg"
            type="button"
            mat-button
            color="default"
            ix-auto-type="button"
            ix-auto-identifier="CANCEL"
            [ix-auto]=""
            (click)="goBack()"
          >
            {{ 'Cancel' | translate }}
          </button>
          <span *ngFor="let custBtn of conf.customActions">
            <button
              *ngIf="!conf.isCustomActionVisible || conf.isCustomActionVisible(custBtn.id)"
              mat-button
              class="mat-basic"
              ix-auto-type="button"
              id="cust_button_{{ custBtn.name }}"
              ix-auto-identifier="{{ custBtn.name | uppercase }}"
              [color]="custBtn.buttonColor ? custBtn.buttonColor : 'default'"
              [disabled]="conf.isCustomActionDisabled !== undefined ? conf.isCustomActionDisabled(custBtn.id) : custBtn.disabled"
              [type]="custBtn.buttonType ? custBtn.buttonType : 'button'"
              [ix-auto]=""
              (click)="custBtn['function'] ? custBtn['function']() : null"
            >
              {{ custBtn.name | translate }}
            </button>
          </span>
          <span *ngIf="conf.compactCustomActions">
            <button
              name="compactCustomActions"
              mat-icon-button
              ix-auto
              ix-auto-type="button"
              ix-auto-identifier="keymenu"
              type="button"
              [matMenuTriggerFor]="keymenu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #keymenu="matMenu">
              <span *ngFor="let action of conf.compactCustomActions">
                <button
                  *ngIf="!conf.isCustomActionVisible || conf.isCustomActionVisible(action.id)"
                  name="action.id"
                  mat-menu-item
                  ix-auto
                  ix-auto-type="option"
                  ix-auto-identifier="action.name"
                  [disabled]="conf.isCustomActionDisabled !== undefined ? conf.isCustomActionDisabled(action.id) : action.disabled"
                  (click)="action['function']()"
                >
                  {{ action.name | translate }}
                </button>
              </span>
            </mat-menu>
          </span>
          <button
            *ngIf="conf.routeDelete"
            mat-button
            class="btn btn-block btn-danger"
            type="button"
            ix-auto-type="button"
            ix-auto-identifier="DELETE"
            [ix-auto]=""
          >
            {{ 'Delete' | translate }}
          </button>
          <!-- </div> -->
          <div id="success-msg-wrapper">
            <div
              *ngIf="success && entityForm.form.dirty === false"
              type="success"
              id="successfully_updated"
            >
              {{ successMessage | translate }}
            </div>
          </div>
        </mat-card-actions>
      </div>
      <!-- <div class="btn-group btn-group-justified"> -->
    </form>
    <span
      *ngIf="conf.formMessage"
      id="form_message"
      class="form-message"
      [ngClass]="conf.formMessage.type"
      [innerHTML]="conf.formMessage.content | translate"
    ></span>
    <mat-error *ngIf="error" type="danger" id="error_message">
      <div [innerHTML]="error"></div>
    </mat-error>
  </div>
</mat-card>
