<h1 mat-dialog-title>
  <mat-icon *ngIf="conf.icon" class="general-dialog-icon">
    {{ conf.icon }}
  </mat-icon>
  <span *ngIf="conf.title">{{ conf.title }}</span>
</h1>
<div mat-dialog-content class="general-dialog-content-container">
  <div class="generial-dialog-content">
    <span
      *ngIf="conf.is_html; else normalContent"
      [innerHTML]="conf.message | translate"
    ></span>
    <ng-template #normalContent>
      <span>{{ conf.message }}</span>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button
    *ngIf="!conf.hideCancel"
    mat-button
    class="mat-button mat-accent"
    (click)="dialogRef.close(false)"
  >
    {{ conf.cancelBtnMsg || 'Cancel' | translate }}
  </button>
  <button
    mat-button
    class="mat-button mat-primary"
    (click)="dialogRef.close(true)"
  >
    {{ conf.confirmBtnMsg || 'Close' | translate }}
  </button>
</div>
